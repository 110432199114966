.limit-modal .modal-content{
    background-color: #001842;
}
.limit-modal .modal-header{
    padding: 15px 26px;
    border: none;
}
.limit-modal .modal-title{
    /* color: #7c8fa0; */
    color: #a7bbcd;
    margin: 0;
    font-size: 16px;
}
.limit-modal .close{
    color: #7c8fa0;
    font-weight: 300;
    font-size: 20px !important;
    box-shadow: none;
    text-shadow: none;
}
.limit-modal .close:hover{
    color: #fff;
}
.limit-modal .modal-body{
    padding-top: 0;
}
.limit-modal-table .table-responsive thead {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    background: #03071e;
}
.limit-modal-table .table-responsive thead th {
    color: #fff;
    font-weight: 500;
}
.limit-modal-table .table-bordered{
    border: none;
}
.limit-modal-table .table-bordered thead td,
.limit-modal-table .table-bordered thead th{
    border: none;
    color: #fff;
    font-weight: 500;
    border-bottom: 1px solid hsla(0,0%,84.3%,.058823529411764705);
}
.limit-modal-table .table-bordered td,
.limit-modal-table .table-bordered th{
    border: none;
    border-bottom: 1px solid hsla(0,0%,84.3%,.058823529411764705);
    /* color: #7c8fa0; */
    font-size: 14px;
    color: #a7bbcd;;
}