.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  height: 66px;
  background: transparent;
  /* background: linear-gradient(180deg, #1f2f3c, #16212c); */
}
.header.sticky {
  z-index: 999;
}

.main-wrapper {
  overflow-x: hidden;
}

.header-logo {
  max-width: 120px;
  width: 100%;
}

.header-logo img {
  width: 80px;
  position: absolute;
  top: -25px;
  left: 0;
  cursor: pointer;
}

.header-logo .main-logo-border {
  -webkit-animation: spin 8s linear infinite;
  -moz-animation: spin 8s linear infinite;
  animation: spin 8s linear infinite;
}
.add-sidebar .header.sticky{
  z-index: -1;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* .header-logo img {
  width: 100px;
  position: absolute;
  top: -34px;
} */

.mobile-login,
.header-logo-mobile {
  display: none;
}

.header-logo h2 {
  color: #f6ad75;
  font-weight: 700;
  font-size: 36px;
  letter-spacing: 0.5px;
  margin: 0;
}

.header-logo a:hover {
  text-decoration: none;
}

.header-top-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 14px 0px;
}

.main-game-list {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0px 0px;
}

.header-logo {
  padding: 0px 15px;
}

.header-top-area .navbar-expand .navbar-nav .nav-link {
  padding: 0px 30px;
  font-weight: 600;
  font-size: 16px;
  position: relative;
  color: #fff;
  display: block;
}

.header-top-area .navbar-expand-lg .navbar-nav .nav-link:first-child {
  padding-left: 6px;
}

.header-top-area .navbar-light .navbar-nav .nav-link {
  color: #7c8fa0;
}

.header-top-area .navbar-light .navbar-nav .nav-link.active span::after {
  width: 100%;
  height: 2px;
  content: "";
  display: block;
  position: absolute;
  background: #f6ad75;
  left: 0;
  bottom: -24px;
}

.header-top-area .navbar-expand-lg .navbar-nav .nav-link {
  padding: 0 30px;
}

.header-top-area .navbar-expand-lg .navbar-nav .nav-link span {
  position: relative;
  font-size: 16px;
  line-height: 14px;
  font-family: "Averta-Semibold";
}

.header-top-area nav.navbar.navbar-expand.navbar-light {
  padding: 0;
}

.header-top-area .navbar-light .navbar-nav .nav-link.active {
  color: #f6ad75;
}

.header-top-area .navbar-light .navbar-nav .nav-link.active:hover {
  color: #f6ad75;
}

.header-top-area .navbar-expand-lg .navbar-nav .nav-link span img {
  margin-right: 5px;
}

.navbar-expand-lg {
  padding: 0 !important;
}

.header-top-login ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-top-login ul li span {
  display: block;
  padding: 12px 20px;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s;
  font-size: 14px;
  line-height: 14px;
  color: #7c8fa0;
  font-weight: 600;
}

.header-top-login ul li span a {
  color: #fff;
}

.header-top-login ul li span a:hover,
.header-top-login ul li span a:focus {
  color: #fff;
  text-decoration: none;
}

.header-top-login ul li:last-child span {
  background: #f6ad75;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #1b1f22;
}

.header-top-login ul li:last-child span:hover {
  background: #f6ad75;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #1b1f22;
}

.header-top-login ul li span i {
  margin-right: 5px;
}

.header-top-area .navbar-light .navbar-nav .nav-link:focus,
.header-top-area .navbar-light .navbar-nav .nav-link:hover {
  color: #7c8fa0;
}

.header-top-login ul li.or {
  margin: 0 10px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

ul {
  margin: 0 !important;
  list-style-type: none !important;
  padding: 0 !important;
}

.header-top-login {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  justify-content: flex-end;
}

.header-top-login > a {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #7c8fa0;
  margin-left: 20px;
}

.header-top-login > a:hover {
  text-decoration: none;
  color: #7c8fa0;
}

.header-top-login.mobile-login {
  display: none;
}

.my-profile-menu {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.p-pic img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 5px;
}

.user-pic img {
  width: 22px;
  height: 22px;
  object-fit: cover;
  border-radius: 15px;
}

.p-name h5 {
  font-size: 14px;
  margin: 0;
  color: #fff;
}

.p-name {
  padding-left: 10px;
}

.my-profile-menu .dropdown .btn,
.my-profile-menu .dropdown .btn:hover,
.my-profile-menu .dropdown .btn:focus {
  background: transparent !important;
  color: #fff;
  border-color: transparent;
  border: none;
  padding: 0;
  font-size: 14px;
  box-shadow: none;
  outline: none;
}

.my-profile-menu .dropdown-item {
  font-size: 13px;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none !important;
}

.p-name .dropdown-menu.show {
  display: block;
  right: 0;
  left: inherit;
  border: 1px solid #001a47;
  margin-top: 10px !important;
  /* background: #16212c; */
  background: linear-gradient( 180deg ,#001a47,#03051a);
  border-radius: 2px;
  padding: 6px;
}

.p-name .dropdown-menu a {
  display: block;
  width: 100%;
  clear: both;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  padding: 8px 15px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7c8fa0;
}

.p-name .dropdown-menu a:hover {
  text-decoration: none;
  background: #212e3b;
  border-radius: 2px;
  color: #7c8fa0;
}

.loggedin-menu {
  display: flex;
  align-items: center;
}

.loggedin-menu .credit-input form {
  display: flex;
  max-width: 180px;
  position: relative;
}
.loggedin-menu .credit-input .form-control,
.loggedin-menu .credit-input .form-control:focus {
  height: 35px;
  padding-left: 30px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  box-shadow: none;
  background: rgba(14, 22, 30, 0.55);
  border: 1px solid #192633;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100px;
  pointer-events: none;
}

.loggedin-menu .credit-input .form-control::-webkit-outer-spin-button,
.loggedin-menu .credit-input .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loggedin-menu .credit-input .form-control {
  -moz-appearance: textfield;
}

.loggedin-menu a {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #7c8fa0;
}

.loggedin-menu a:hover {
  text-decoration: none;
  color: #f6ad75;
}

.loggedin-menu .credit-input {
  margin-left: 20px;
}

.loggedin-menu .credit-input form .btn,
.loggedin-menu .credit-input form .btn:hover,
.loggedin-menu .credit-input form .btn:focus {
  height: 35px;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #1b1f22;
  background: #f6ad75;
  border-radius: 3px;
  border-color: #f6ad75;
  box-shadow: none;
}

.loggedin-menu .btn-primary:not(:disabled):not(.disabled):active,
.loggedin-menu .btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #f6ad75;
  border-radius: 3px;
  border-color: #f6ad75;
  box-shadow: none;
}

.loggedin-menu .credit-input .form-control::placeholder {
  color: #fff;
}

.loggedin-menu .credit-input form img {
  width: 10px;
  position: absolute;
  z-index: 9;
  left: 11px;
  top: 17px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.p-name .dropdown-menu a img {
  margin-right: 5px;
}
.mob-logo {
  display: none;
}
.language select,
.language select:focus {
  background: transparent;
  border: none;
  color: #f6ad75;
  margin-left: 15px;
  outline: none;
  box-shadow: none;
}
.language select option {
  color: black;
  background: transparent;
  padding: 0px 10px;
}

.header-top-area button#dropdown-profile {
  color: #ffffff !important;
  background-color: transparent;
  border-color: transparent;
}
.content-wrapper .form-input input.form-control {
  padding-left: 10px;
}
.content-wrapper .settings-content .form-label {
  margin-left: 3px;
}
.content-wrapper button.save-btn.btn.btn-primary {
  color: rgb(2, 2, 2);
  background-color: #eda773;
  border-color: #eda773;
  font-weight: 400;
}
.header-top-area .dropdown-menu.show {
  min-width: 50px !important;
  font-size: 14px;
  background: #00153d;
  padding: 4px;
  /* width: 10rem !important; */
}
.header-top-area .dropdown-menu a:hover {
  background: #121b24;
}
.header-top-area .dropdown-item {
  color: rgb(0, 0, 0);
}
.wallet-amount img {
  width: 25px;
  margin-right: 10px;
}
.header-top-login .wallet-amount {
  color: #fff;
  margin-right: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.volume-btn button {
  background: #121b24;
  border: 1px solid #121b24;
  color: #fff !important;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  transition: all 0.5s;
}

.volume-btn button:hover {
  background: #1d2d39;
  border: 1px solid #243746;
}

.rules-box {
  margin-left: 20px;
}

.rules-box span {
  background: #eb2958;
  border: 1px solid #eb2958;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  padding: 4px 10px;
  border-radius: 3px;
  min-width: 80px;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
}

.rules-box span:hover {
  background: #1d2d39;
  border: 1px solid #243746;
}

.wallet-input {
  /* background: #121b24; */
  /* border: 1px solid #121b24; */
  display: flex;
  padding: 5px 10px;
  /* border-radius: 3px; */
  color: #fff;
  font-size: 14px;
  transition: all 0.5s;
  cursor: pointer;
  align-items: center;
}

.wallet-input {
  margin-left: 20px;
}

.wallet-input img {
  margin-right: 8px;
  width: 25px;
}
button.exit-game.btn.btn-primary{
  background: #f6ad75!important;
  font-weight: 600;
  height: 38px;
  border: #f6ad75;
  font-size: 14px;
  line-height: 14px;
  border-radius: 5px;
  color: #1b1f22!important;
  outline: none!important;
  transition: all 5s;
  box-shadow: none!important;
  padding: 12px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}
button.exit-game.btn.btn-primary i{
  margin-right: 8px;
}
/* .wallet-input:hover {
  background: #1d2d39;
  border: 1px solid #243746;
} */

/*************** Media Query ****************/

@media screen and (max-width: 991px) {
  .profile-menu {
    min-height: auto;
  }

  .header-top-area {
    min-height: 62px;
  }

  .header-logo {
    width: 100px;
  }

  .header-logo img {
    width: 65px;
  }
  .navbar-light .navbar-toggler-icon {
    display: none !important;
  }

  .header-top-area .navbar-light .navbar-toggler.collapsed {
    padding: 0;
    border: none;
    color: transparent;
    position: relative;
    width: 26px;
    height: 26px;
    border-top: 2px solid #ffffff;
    border-radius: 0;
    top: 6px;
  }

  .header-top-area .navbar-light .navbar-toggler {
    padding: 0;
    border: none;
    color: transparent;
    position: relative;
    width: 26px;
    height: 26px;
    border-top: 2px solid transparent;
    border-radius: 0;
    top: 2px;
  }
  .header-top-login {
    display: none;
  }

  .header-top-area .navbar-light .navbar-toggler.collapsed::before {
    content: "";
    background: #ffffff;
    width: 20px;
    height: 2px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 6px;
    left: 0;
    right: 0;
    transition: 0.4s all ease;
    transform: inherit;
  }

  .header-top-area .navbar-light .navbar-toggler.collapsed::after {
    content: "";
    background: #ffffff;
    width: 26px;
    height: 2px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 14px;
    right: 0;
    transition: 0.4s all ease;
    transform: inherit;
  }

  .header-top-area .navbar-light .navbar-toggler::before {
    content: "";
    background: #ffffff;
    width: 26px;
    height: 2px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    transition: 0.4s all ease;
    transform: rotate(-45deg);
  }
  .header-top-area .navbar-light .navbar-toggler::after {
    content: "";
    background: #ffffff;
    width: 26px;
    height: 2px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 8px;
    right: 0;
    transition: 0.4s all ease;
    transform: rotate(45deg);
  }

  .navbar-collapse {
    position: fixed !important;
    top: 0;
    background: #16212c;
    bottom: 0;
    width: 300px;
    border-right: 1px solid #252831;
    left: -300px;
    transition: 0.4s all ease !important;
    display: block !important;
    flex-grow: inherit !important;
    flex-basis: inherit !important;
    height: 100vh !important;
  }

  .navbar-collapse.show {
    left: 0;
  }
  .header-top-area .navbar-expand-lg .navbar-nav .nav-link {
    padding: 20px 30px;
  }
  .header-top-area .navbar-expand-lg .navbar-nav .nav-link:first-child {
    padding-left: 30px;
  }
  .header-top-area .navbar-light .navbar-nav .nav-link.active span::after {
    display: none;
  }
  .header-top-area .navbar-light .navbar-nav .nav-link.active {
    color: #f6ad75;
  }
  .mobile-login {
    position: fixed;
    bottom: 30px;
    left: 30px;
    max-width: inherit;
    width: inherit;
  }

  .header-top-login.mobile-login ul {
    display: flex;
    justify-content: center;
  }
  .header-top-login ul li span {
    font-size: 14px;
    padding: 8px 10px;
    min-width: 108px;
  }

  .header-top-login ul li:first-child span {
    background: #1e2e3b;
    border-radius: 3px;
  }

  .navbar-nav {
    padding-top: 10px;
  }
  .header-logo-mobile {
    display: block;
    padding-left: 30px;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .show .mobile-login {
    display: block;
  }
  .header-logo-mobile img {
    max-width: 224px;
  }

  .header-logo-mobile h2 {
    color: #f6ad75;
    font-weight: 700;
    font-size: 36px;
    letter-spacing: 0.5px;
    margin: 0;
  }

  .header-logo-mobile a:hover {
    text-decoration: none;
  }
}

@media screen and (max-width: 340px) {
  .navbar-collapse {
    width: 260px;
  }
  .header-top-login ul li span {
    font-size: 13px;
    padding: 8px 10px;
    min-width: 90px;
  }

  .header-top-area .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 14px;
  }
}

button{
  font-weight: 600 !important;
  color: #1b1f22!important;
}