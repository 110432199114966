.history-popup .modal-content {
    background: linear-gradient( 
180deg
 , #1f2f3c, #16212c);
    z-index: 999999;
}
.history-popup .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 30px;
}
.history-popup-content .user-image-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.history-popup-content .user-image-section img {
    height: 120px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px #141f27;
}

.history-popup-content .user-image-section .round-id-section {
    width: calc(100% - 145px);
}

.round-id-section h6 {
    BACKGROUND: #112231;
    color: white;
    padding: 15px 10px;
    font-weight: 400;
    font-size: 13px;
    border-radius: 5px;
}
.payout-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.payout-section .bet {
    width: 30%;
}
.payout-section .bet h6{
    BACKGROUND: #112231;
    color: white;
    padding: 15px 10px;
    font-weight: 400;
    font-size: 13px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}
.payout-section .bet p {
    color: #cecccc;
    margin-bottom: 0px;
    font-size: 12px;
}
.payout-textid .bet p {
    color: #cecccc;
    margin-bottom: 0px;
    font-size: 12px;
}
.payout-textid .bet h6{
    BACKGROUND: #112231;
    color: white;
    padding: 15px 10px;
    font-weight: 400;
    font-size: 13px;
    border-radius: 5px;
}
.payout-textid {
    margin-top: 15px;
}
.payout-textid h6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.history-heading img {
    height: 15px;
    cursor: pointer;
}

.history-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.history-heading h6 {
    color: white;
    font-size: 20px;
}
.payout-textid i {
    BACKGROUND: #1d486f;
    color: white;
    padding: 6px;
    border-radius: 3px;
    margin-left: 2px;
    cursor: pointer;
}