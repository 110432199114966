@import "https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap";

body {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: #121b24 !important;
}
.modal-body {
  background: linear-gradient(180deg, #001a47, #03051a) !important;
}
.main_content {
  position: relative;
}

.content-wrapper {
  min-height: calc(100vh - 120px);
  margin-top: 0px;
}

.coming-soon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 120px);
  flex-wrap: wrap;
}

.coming-soon-box h2 {
  color: #a9f55c;
  font-weight: 700;
  font-size: 50px;
  letter-spacing: 0.5px;
  margin: 0;
  width: 100%;
}

.coming-soon-box p {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 14px;
  color: #7c8fa0;
  padding-top: 10px;
}

.modal-content {
  z-index: 999999999;
}

/* .header-top-area button#dropdown-profile {
  color: #7c8fa0;
  background-color: transparent;
  border-color: transparent;
  display: flex;
  align-items: center;
} */
