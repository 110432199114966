@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
/***************** Reset Css *******************/

html,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.game-result {
  color: #fff;
}
/***************** Language *******************/

.language {
  position: absolute;
  right: 10px;
  top: 10px;
}

.language select {
  background: #000000;
  border-color: #282828;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 20px;
  border-radius: 30px;
  cursor: pointer;
}

.language select:focus {
  outline: none;
}

.bottom-table {
  margin-top: 50px;
}
/***************** Roulette *******************/

.roulette-wrapper {
  padding-top: 110px;
}

.spin-btns {
  position: absolute;
  left: 0;
  top: 0;
}

.number:nth-child(1) {
  transform: rotateZ(9.4736842deg);
}
.number:nth-child(2) {
  transform: rotateZ(18.9473684deg);
}
.number:nth-child(3) {
  transform: rotateZ(28.4210526deg);
}
.number:nth-child(4) {
  transform: rotateZ(37.8947368deg);
}
.number:nth-child(5) {
  transform: rotateZ(47.368421deg);
}
.number:nth-child(6) {
  transform: rotateZ(56.8421052deg);
}
.number:nth-child(7) {
  transform: rotateZ(66.3157894deg);
}
.number:nth-child(8) {
  transform: rotateZ(75.7894736deg);
}
.number:nth-child(9) {
  transform: rotateZ(85.263158deg);
}
.number:nth-child(10) {
  transform: rotateZ(94.7368422deg);
}
.number:nth-child(11) {
  transform: rotateZ(104.21052639deg);
}
.number:nth-child(12) {
  transform: rotateZ(113.6842106deg);
}
.number:nth-child(13) {
  transform: rotateZ(123.1578948deg);
}
.number:nth-child(14) {
  transform: rotateZ(132.631579deg);
}
.number:nth-child(15) {
  transform: rotateZ(142.1052632deg);
}
.number:nth-child(16) {
  transform: rotateZ(151.5789474deg);
}
.number:nth-child(17) {
  transform: rotateZ(161.0526316deg);
}
.number:nth-child(18) {
  transform: rotateZ(170.5263158deg);
}
.number:nth-child(19) {
  transform: rotateZ(180deg);
}
.number:nth-child(20) {
  transform: rotateZ(189.4736842deg);
}
.number:nth-child(21) {
  transform: rotateZ(198.9473684deg);
}
.number:nth-child(22) {
  transform: rotateZ(208.4210526deg);
}
.number:nth-child(23) {
  transform: rotateZ(217.8947368deg);
}
.number:nth-child(24) {
  transform: rotateZ(227.368421deg);
}
.number:nth-child(25) {
  transform: rotateZ(236.8421052deg);
}
.number:nth-child(26) {
  transform: rotateZ(246.3157894deg);
}
.number:nth-child(27) {
  transform: rotateZ(255.7894736deg);
}
.number:nth-child(28) {
  transform: rotateZ(265.2631578deg);
}
.number:nth-child(29) {
  transform: rotateZ(274.7368419deg);
}
.number:nth-child(30) {
  transform: rotateZ(284.2105261deg);
}
.number:nth-child(31) {
  transform: rotateZ(293.68421029deg);
}
.number:nth-child(32) {
  transform: rotateZ(303.15789449deg);
}
.number:nth-child(33) {
  transform: rotateZ(312.6315786deg);
}
.number:nth-child(34) {
  transform: rotateZ(322.1052628deg);
}
.number:nth-child(35) {
  transform: rotateZ(331.57894699999997deg);
}
.number:nth-child(36) {
  transform: rotateZ(341.05263119deg);
}
.number:nth-child(37) {
  transform: rotateZ(350.52631539deg);
}
.main {
  width: 350px;
  margin: 0px auto 0px;
  position: relative;
}
.plate {
  background-color: gray;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  position: relative;
  /* -webkit-animation: rotate 24s infinite linear;
  animation: rotate 24s infinite linear; */
}
/* .spin-fast {
  -webkit-animation: rotate 1s infinite linear;
  animation: rotate 1s infinite linear;
}

.spin-fast .data {
  animation: rotate 1s reverse linear infinite;
} */

.activate-spin {
  animation-name: rotatespin;
  animation-duration: 9s;
  transition: transform 9s ease-out;
  -webkit-transition: transform 9s ease-out;
  -webkit-animation-name: rotatespin;
  -webkit-animation-duration: 9s;
}

@-webkit-keyframes rotatespin {
  0% {
    left: 50%;
    transform: translateX(-50%) rotateZ(0deg);
    -webkit-transform: translateX(-50%) rotateZ(0deg);
  }
  100% {
    left: 50%;
    transform: translateX(-50%) rotateZ(1080deg);
    -webkit-transform: translateX(-50%) rotateZ(1080deg);
  }
}
@keyframes rotatespin {
  0% {
    left: 50%;
    transform: translateX(-50%) rotateZ(0deg);
    -webkit-transform: translateX(-50%) rotateZ(0deg);
  }
  100% {
    left: 50%;
    transform: translateX(-50%) rotateZ(1080deg);
    -webkit-transform: translateX(-50%) rotateZ(1080deg);
  }
}
.plate:after,
.plate:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
}
.plate:after {
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  border: 6px solid #000000;
  box-shadow: inset 0px 0px 0px 3px #e3e3e3, 0px 0px 0px 10px #000000;
}
.plate:before {
  background: rgba(0, 0, 0, 0.65);
  border: 1px solid #e3e3e3;
  box-shadow: inset 0px 0px 0px 2px #e3e3e3;
  top: 12%;
  left: 12%;
  right: 12%;
  bottom: 12%;
  z-index: 1;
}
.number {
  width: 32px;
  height: 175px;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 0;
  left: calc(50% - (32px / 2));
  transform-origin: 50% 100%;
  background-color: transparent;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 175px solid #04000f;
  box-sizing: border-box;
}
.number:nth-child(odd) {
  border-top-color: #fe2247;
}
.number:nth-child(38),
.number-new {
  border-top-color: #419e3f !important;
}
.pit {
  color: #fff;
  padding-top: 12px;
  width: 32px;
  display: inline-block;
  font-size: 13px;
  /* transform: scale(1, 1.8); */
  position: absolute;
  top: -175px;
  left: -16px;
  font-weight: 500;
}
.inner {
  display: block;
  height: 350px;
  width: 350px;
  position: relative;
  margin: 0;
}
.inner:after,
.inner:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
}
.inner:after {
  z-index: 3;
  top: 24%;
  right: 24%;
  bottom: 24%;
  left: 24%;
  background-color: #000000;
  border: 3px solid #e3e3e3;
}
.inner:before {
  top: 20%;
  bottom: 20%;
  left: 20%;
  right: 20%;
  content: "";
  color: #fff;
  font-size: 60px;
  z-index: 5;
  border-radius: 0;
}
.inner[data-spinto="1"]:before {
  transform: rotateZ(-2596deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="2"]:before {
  transform: rotateZ(-2766deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="3"]:before {
  transform: rotateZ(-2842deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="4"]:before {
  transform: rotateZ(-2785deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="5"]:before {
  transform: rotateZ(-2633deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="6"]:before {
  transform: rotateZ(-2728deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="7"]:before {
  transform: rotateZ(-2520deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="8"]:before {
  transform: rotateZ(-2671deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="9"]:before {
  transform: rotateZ(-2558deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="10"]:before {
  transform: rotateZ(-2653deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="11"]:before {
  transform: rotateZ(-2690deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="12"]:before {
  transform: rotateZ(-2861deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="13"]:before {
  transform: rotateZ(-2710deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="14"]:before {
  transform: rotateZ(-2576deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="15"]:before {
  transform: rotateZ(-2804deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="16"]:before {
  transform: rotateZ(-2614deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="17"]:before {
  transform: rotateZ(-2747deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="18"]:before {
  transform: rotateZ(-2538deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="19"]:before {
  transform: rotateZ(-2794deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="20"]:before {
  transform: rotateZ(-2586deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="21"]:before {
  transform: rotateZ(-2776deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="22"]:before {
  transform: rotateZ(-2548deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="23"]:before {
  transform: rotateZ(-2662deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="24"]:before {
  transform: rotateZ(-2624deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="25"]:before {
  transform: rotateZ(-2756deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="26"]:before {
  transform: rotateZ(-2833deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="27"]:before {
  transform: rotateZ(-2718deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="28"]:before {
  transform: rotateZ(-2870deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="29"]:before {
  transform: rotateZ(-2528deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="30"]:before {
  transform: rotateZ(-2680deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="31"]:before {
  transform: rotateZ(-2567deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="32"]:before {
  transform: rotateZ(-2813deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="33"]:before {
  transform: rotateZ(-2604deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="34"]:before {
  transform: rotateZ(-2738deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="35"]:before {
  transform: rotateZ(-2491deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="36"]:before {
  transform: rotateZ(-2700deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="0"]:before {
  transform: rotateZ(-2822deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="00"]:before {
  transform: rotateZ(-2643deg);
  transition: transform 9s ease-out;
  content: "\2022";
}
.inner.rest:before {
  transition: top 0.5s ease-in, right 0.5s ease-in, bottom 0.5s ease-in,
    left 0.5s ease-in;
  top: 18%;
  right: 18%;
  bottom: 18%;
  left: 18%;
}

.btn .btn-label {
  padding: 12px;
  white-space: nowrap;
}
.btn.btn-reset {
  background: #21c9a6;
  border: 1px solid #21c9a6;
}
.btn.disabled {
  opacity: 0.2;
  transition: opacity 0.24s linear;
  cursor: not-allowed;
}

@-webkit-keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.data {
  display: block;
  position: absolute;
  top: 30%;
  right: 30%;
  bottom: 30%;
  left: 30%;
  border-radius: 50%;
  /* animation: rotate 24s reverse linear infinite; */
  perspective: 2000px;
  z-index: 9;
}
.data .data-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.72s;
  transform-style: preserve-3d;
}
.data.reveal .data-inner {
  transform: rotateY(180deg);
}
.data .mask,
.data .result {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}
.data .mask {
  color: #fff;
  font-size: 20px;
  margin: auto;
  line-height: 1.4;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data .result {
  background-color: #21c9a6;
  transform: rotateY(180deg);
  align-items: center;
  color: #fff;
}
.data .result-number {
  font-size: 72px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}
.data .result-color {
  text-transform: uppercase;
  font-size: 21px;
  line-height: 1;
}
.previous-results {
  max-width: 150px;
  position: fixed;
  text-transform: capitalize;
  right: 10px;
  top: 50px;
  width: 100%;
}
.previous-list {
  perspective: 2000;
  margin: 0;
}
.previous-result {
  display: flex;
  margin: 1px;
  padding: 6px 12px;
  color: #fff;
}
.previous-result:first-child {
  -webkit-animation: flipin 0.48s ease-out;
  animation: flipin 0.48s ease-out;
}
.previous-result .previous-number {
  flex: 1;
}
.color-green {
  background-color: #21c9a6;
}
.color-black {
  background-color: black;
}
.color-red {
  background-color: #eb2958;
}
.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
@-webkit-keyframes flipin {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes flipin {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.data-inner span {
  color: #fff;
  font-size: 25px;
}

/***************** Bet Table *******************/

.roulette-bet-table {
  width: 720px;
  margin: 0px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 25px;
}

.roulette-bet-table table.bet-table {
  font-size: 24px;
}
.roulette-bet-table table {
  text-align: center;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  cursor: pointer;
}

.roulette-bet-table table tr {
  font-size: 16px;
  line-height: 0px;
}

.roulette-bet-table table.bet-table tr:first-child td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 2px solid #f8f5ff;
}
.roulette-bet-table table.bet-table tr:first-child td:nth-child(2),
.roulette-bet-table table.bet-table tr:first-child td:nth-child(3) {
  border-top: 2px solid white;
}
.roulette-bet-table table.bet-table tr:first-child td:nth-child(3) {
  border-right: 2px solid white;
}
.roulette-bet-table table.bet-table tr:first-child td {
  border-top: 0px solid #f8f5ff;
  border-bottom: 2px solid white;
}
.roulette-bet-table table.bet-table td {
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  /* border-right: 1px solid #f8f5ff;
  border-bottom: 1px solid #f8f5ff; */
  height: 50px;
  width: 50px;
  color: #fff;
  vertical-align: middle;
}
.red {
  background-color: #fe2247 !important;
}

.black {
  background-color: black !important;
}

.green {
  background-color: #419e3f !important;
}

.roulette-bet-table table.bet-table tr:first-child td:last-child {
  border-top-right-radius: 4px;
}

.roulette-bet-table table.bet-table tr td:first-child,
.roulette-bet-table table.bet-table tr td:last-child {
  width: 60px;
}

.roulette-bet-table table.bet-table td:last-child {
  font-size: 16px;
  background-color: #121b24;
}

.roulette-bet-table .grid-bet-table > div:first-child {
  border-radius: unset;
  border-top-left-radius: 4px;
}

/* .roulette-bet-table .grid-bet-table {
  border-radius: 0 0 4px 4px;
  border-width: 0 0 1px 1px;
  font-size: 16px;
  height: 100px;
  width: 618px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 102.8px);
  grid-template-rows: 1fr 1fr;
  background-color: #121b24;
  border: solid #f8f5ff;
  border: 1px solid #f8f5ff;
  margin-top: -1px;
  position: relative;
  left: -2px;
} */
.roulette-bet-table .grid-bet-table {
  border-radius: 0 0 4px 4px;
  border-width: 0 0 1px 1px;
  font-size: 16px;
  height: 100px;
  width: 570px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 94.8px);
  grid-template-rows: 1fr 1fr;
  /* background-color: #03071d; */
  background: linear-gradient(180deg, #001a47, #03051a);
  border: solid #f8f5ff;
  border: 1px solid #f8f5ff;
  margin-top: -1px;
  position: relative;
  left: 23px;
}

.roulette-bet-table .grid-bet-table > div.row-selection {
  grid-column-start: span 2;
  border-bottom: 1px solid #f8f5ff;
}

.roulette-bet-table .grid-bet-table > div {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  border: solid #f8f5ff;
  border-width: 0 0 1px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-orientation: mixed;
  border-left-width: 0;
  border-bottom-width: 0;
  border-right: 1px solid #f8f5ff;
  border-top-width: 0;
  -webkit-writing-mode: unset;
  -ms-writing-mode: unset;
  writing-mode: unset;
  font-size: 16px;
  font-weight: 600;
}

.roulette-bet-table .grid-bet-table > div:nth-child(4) {
  border-bottom-left-radius: 4px;
}

.roulette-bet-table .grid-bet-table > div:not(:nth-child(-n + 3)) {
  margin-bottom: 0px;
}

.roulette-bet-table .grid-bet-table > div:last-child {
  border-bottom-left-radius: 4px;
  border-right: 0;
}

.roulette-bet-table .grid-bet-table > div.color-selection {
  width: unset;
  overflow: hidden;
}
.roulette-bet-table .grid-bet-table > div.color-selection svg {
  width: auto;
  -webkit-transform: unset;
  transform: unset;
}

.roulette-bet-table table.bet-table tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}

.roulette-bet-table .grid-bet-table > div:nth-child(3) {
  border-right: 0;
}
td.selected-area {
  width: 4px !important;
  height: 3px !important;
  background: #ffffff !important;
}
.active-class {
  background: #0d2b57 !important;
}

.hover-class {
  background: #0d2b57 !important;
}

/****** new css **************/
/* .roulette-table-section {
  background: #16212c;
  width: 64%;
  border-radius: 5px;
  padding: 20px 10px;
} */
.roulette-table-section .timer,
.roulette-table-section .username {
  font-size: 14px;
  color: #9d9d9d;
}
.roulette-bet-table table.bet-table tr:first-child td:first-child {
  border-left: 2px solid white !important;
  border-right: 0px solid #f8f5ff;
  border-bottom: 2px solid #f8f5ff;
  border-top: 2px solid white;
}
.roulette-bet-table table.bet-table tr td:last-child {
  border-right: 2px solid white;
}
.winner-section {
  width: 35%;
}
td.selected-area-zero {
  width: 4px !important;
  background: #ffffff;
}
.game-table-section {
  display: flex;
  justify-content: space-between;
}
.roulette-bet-table table.bet-table tr:last-child td:last-child {
  border-bottom: 1px solid white;
}
.winner-section .table-responsive {
  background: #16212c;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
}

.winner-section .table-responsive table {
  background: transparent;
  border: none;
}

.winner-section .table-responsive table tr td,
.winner-section .table-responsive table tr th {
  border: none;
  font-size: 12px;
  color: #7c8fa0;
}
.bottom-table .table-responsive {
  background: #03092280;
  padding: 0px;
}

.bottom-table .table-responsive table {
  background: transparent;
  border: none;
}

.bottom-table .table-responsive table tr td,
.bottom-table .table-responsive table tr th {
  border: none;
}

.bottom-table .table-responsive table thead {
  background: #121b24;
}

.bottom-table .table-responsive table thead th {
  font-size: 15px;
  font-weight: 600;
}
.wallet-amount img {
  width: 25px;
  margin-right: 10px;
}

.language {
  display: flex;
}

.wallet-amount {
  color: #ffffff;
  margin-right: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 13px;
}
.roulette-game-page {
  margin: 30px 0px 0px;
}
.bet-section {
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #001a47, #03051a);
  max-width: 60%;
  margin: 28px auto 0px;
  padding: 5px;
  border-radius: 5px;
}

.bet-section .bet-box {
  background: #121b24;
  margin: 5px;
  height: 35px;
  width: 35px;
  text-align: center;
  border-radius: 3px;
  font-size: 14px;
  line-height: 35px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-chips-section {
  /* background: #16212c; */
  background: linear-gradient(180deg, #001a47, #03051a);
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
}

.select-chips-section input {
  background: #121b24;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #121b24;
  width: 100%;
  font-size: 12px;
  min-height: 38px;
}

.chips-box-list button {
  margin: 0px !important;
  width: 25% !important;
  font-size: 14px !important;
}
.select-chips-section h6 {
  color: white;
}

.chips-box-list {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: flex-start;
}
.winner-section table tbody span {
  font-size: 12px;
  text-align: center;
  width: 100%;
}
.place-bet {
  margin-bottom: 10px;
}
.place-bet {
  position: relative;
}

.place-bet img {
  position: absolute;
  left: 5px;
  width: 22px;
  top: 9px;
}

.place-bet input {
  padding-left: 45px;
}
.bet-coins {
  display: flex;
  width: 60%;
  flex-wrap: wrap;
}
.chips-box-list .bet-box {
  padding: 4px;
  /* background: #121b24; */
  margin: 3px 1px;
  min-height: 35px;
  min-width: 40px;
  text-align: center;
  border-radius: 3px;
  font-size: 12px;
  line-height: 35px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
td.no-user {
  background: transparent !important;
}

tr.no-user {
  background: transparent !important;
}

tr.no-user td {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 0px !important;
}
.winner-section .table-responsive {
  height: 400px;
  overflow: auto;
}

.winner-section .table-responsive::-webkit-scrollbar {
  width: 5px;
}

.winner-section .table-responsive::-webkit-scrollbar-track {
  background: #181d22;
}

.winner-section .table-responsive::-webkit-scrollbar-thumb {
  background: #222c37;
}
.bet-box-disabled {
  cursor: not-allowed !important;
}

.roulette-game-page .winner {
  background-color: #21c9a6 !important;
}

.roulette-game-page .winner td {
  color: #fff !important;
}

.roulette-game-page .looser {
  background-color: #eb2958 !important;
}

.roulette-game-page .looser td {
  color: #fff !important;
}
li.number.red-number {
  border-top-color: #fe2247 !important;
}

li.number.black-number {
  border-top-color: #142134 !important;
}
.game-result pre {
  color: #fff;
  background: linear-gradient(180deg, #001a47, #03051a);
  padding: 20px;
  max-width: 800px;
  border-radius: 8px;
  margin-top: 30px;
}

.game-result {
  /* background: #001945; */
  background: linear-gradient(180deg, #001a47, #03051a);
  padding: 20px;
  max-width: 800px;
  border-radius: 8px;
  margin: 50px auto;
}

.game-result h2 {
  color: #fff;
  margin: 0;
  padding-bottom: 30px;
  text-align: center;
}

.game-result form .form-control {
  /* background: #001844; */
  background: #001844;
  border: 1px solid #071a46;
  color: #a9a9a9;
}
.game-result button {
  background: #f6ad75;
  border: 1px solid #f6ad75;
}
.verify-btn {
  display: flex;
  justify-content: center;
}
.bottom-table .table-responsive table tr td .copy-btn {
  width: 35px;
  height: 35px;
  background: #293746;
  border-radius: 6px;
  border-color: #293746;
  padding: 0px !important;
  margin-left: 5px !important;
}

button.rules-btn.btn.btn-primary {
  /* color: red; */
  margin-top: 0px;
  /* background-color: snow; */
  width: 58px;
}
.popup-img img {
  margin: 58px auto 0;
  width: 128px;
  height: 170px;
  display: block;
}
.welcome-img img {
  margin: 58px auto 0;
  width: auto;
  height: 170px;
  display: block;
}

.welcome-img h2 {
  margin: 0px auto;
  text-align: center;
  color: #12033d;
  font-weight: 800;
  padding-top: 40px;
}
.welcome-img {
  padding-top: 55px;
}
.welcome-btn .btn-primary {
  margin: 32px 146px 0;
  width: 182px;
  height: 50px;
  border-radius: 4px;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #0d42ff;
}
.popup-img h2 {
  margin: 48px auto 0;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 25px;
}
.popup-btn .btn-secondary {
  margin: 32px 17px 0;
  width: 182px;
  height: 50px;
  border-radius: 4px;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #ff2976;
}
.popup-btn .btn-primary {
  margin: 32px 17px 0;
  width: 182px;
  height: 50px;
  border-radius: 4px;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #0d42ff;
}
.header-top-login ul li span {
  display: block;
  padding: 12px 15px !important;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s;
  font-size: 14px;
  line-height: 14px;
  color: #7c8fa0;
}
.header-top-login ul li:last-child span {
  background: #f6ad75;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1b1f22;
}

/**********new css **************/
.roulette-game-page .roulette-table-section {
  width: 50%;
}
.roulette-game-page .winner-section {
  width: 80%;
  margin: 50px auto 0px ;
}
.roulette-game-page .game-table-section {
  align-items: flex-start;
}
.roulette-game-page .bottom-table .nav-tabs {
  border-bottom: 1px solid #80797933;
  background: #03092280;
}
.roulette-game-page .bottom-table .nav-tabs a {
  width: 12%;
  text-align: center;
  color: #7c8fa0;
  background: transparent;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 10px 35px;
}
.roulette-game-page .bottom-table .nav-tabs .nav-link:hover {
  border: 1px solid transparent;
  border-bottom: 1px solid #868686 !important;
}
.roulette-game-page .bottom-table .table-responsive table thead {
  background: transparent;
}
.roulette-game-page .bottom-table .nav-tabs .nav-link.active:hover {
  border-bottom: 2px solid #f6b077 !important;
}
.roulette-game-page .bottom-table .table-responsive table thead th {
  font-size: 13px;
  font-weight: 600;
}
.roulette-game-page .bottom-table .nav-tabs .nav-item.show .nav-link,
.roulette-game-page .bottom-table .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #eb2958;
  border-color: #eb2958 #eb2958 #eb2958;
}

.roulette-game-page .select-chips-section h6 {
  margin-bottom: 5px;
}
.roulette-game-page .select-chips-section button {
  width: auto !important;
  background: #f6ad75 !important;
  border: 1px solid #f6ad75 !important;
  color: white !important;
  text-transform: capitalize;
  padding: 0px 15px !important;
  height: 38px;
  font-weight: 500;
}
.roulette-game-page .select-chips-section {
  padding: 12px 20px !important;
  margin-bottom: 0px;
}
.roulette-game-page .select-chips-section .place-bet {
  margin-bottom: 20px;
}
.payouts-game span {
  color: #ffffff !important;
}
.roulette-game-page .bet-section {
  max-width: fit-content;
}
.bottom-table table tr td {
  font-size: 13px;
  vertical-align: middle;
}
.bottom-table .table-responsive {
  padding-top: 0px;
}
.bottom-table table tr td a {
  color: #f6ad75;
}
.roulette-game-page .bottom-table .nav-tabs .nav-item.show .nav-link,
.roulette-game-page .bottom-table .nav-tabs .nav-link.active {
  color: #f6b077;
  background-color: transparent;
  border-color: transparent;
  border-bottom: 2px solid #f6b57e;
}

/********** rules popup css **************/
.rules-popup .nav-tabs .nav-link.active {
  background-color: #fe2247 !important;
  border: 1px solid #fe2247 !important;
}
.rules-popup .nav-tabs a {
  color: #fff !important;
  padding: 16px;
  border-radius: 4px;
  background-color: #f6ad75 !important;
  border: 1px solid #f6ad75 !important;
  -ms-flex-preferred-size: 48%;
  flex-basis: 48%;
  text-align: center;
}
.rules-popup .nav-tabs {
  display: flex;
  justify-content: space-between;
  border-bottom: none !important;
}
.rules-popup .modal-content {
  height: 600px;
  margin-top: 0px;
  background: #121b24;
  overflow: hidden;
  background: linear-gradient(180deg, #001a47, #03051a);
}
.rules-popup .rules-tab {
  height: 420px;
  overflow-y: auto;
  padding-right: 10px;
  padding-bottom: 20px;
}
.rules-popup .profile-tab {
  height: 335px;
  overflow-y: auto;
  padding-right: 10px;
  padding-bottom: 20px;
}
.rules-popup .rules-tab::-webkit-scrollbar {
  width: 5px;
}
.profile-tabs.tab-pane h3 {
  color: white;
  font-size: 20px;
  margin-top: 13px;
  padding-top: 8px;
}
.profile-tabs.tab-pane h6 {
  color: white;
  font-size: 16px;
  margin-bottom: 13px;
  padding-top: 8px;
  font-weight: 400px;
  display: inline-block;
}
/************* rules popup *************/
.rules-popup .rules-tab::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.rules-popup .rules-tab::-webkit-scrollbar-thumb {
  background: #888;
}
.rules-popup .rules-tab * {
  color: white;
  font-size: 14px;
}
.rules-popup .rules-tab h4 b {
  font-size: 22px !important;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 500;
}
.rules-popup .modal-header {
  border-bottom: none;
  color: white;
}

.rules-popup button.close {
  color: white;
  display: none;
}

.rules-popup .card {
  border-radius: 4px;
  padding: 20px;
  background-color: #031a44;
  margin-bottom: 8px;
  color: white;
  margin-bottom: 20px;
}

.rules-popup .card .card-body {
  padding: 0px;
}

.rules-popup .card .card-body p {
  margin-bottom: 0px;
  font-weight: 300;
  font-size: 14px;
}

.rules-popup .close-icon {
  position: relative;
}

.rules-popup .close-icon img {
  width: 18px;
  position: absolute;
  right: 20px;
  top: -45px;
  cursor: pointer;
}

.rules-popup .card .card-body b {
  display: inline-block;
  padding-bottom: 12px;
}
/******Welcome and age popup *********/
.age-popup .popup-btn {
  /* position: absolute; */
  /* bottom: 35px; */
  /* width: calc(100% - 36px); */
  display: flex;
  justify-content: space-around;
  margin: 40px 0px;
}
.age-popup .popup-btn button {
  margin: 0px !important;
}
.popup-btn button:first-child {
  background: #fe2247 !important;
  border: 1px solid #fe2247;
}
.age-popup .modal-content,
.welcome-popup .modal-content {
  margin-top: 0px;
  background-color: #16212c !important;
  /* min-height: 65vh; */
}
.welcome-popup .welcome-img img {
  width: 125px;
  margin: 0px auto;
  height: auto;
}
.welcome-popup .modal-body {
  padding: 25px;
}
.welcome-img h2 {
  color: white;
  font-weight: 500;
  font-size: 25px;
}
.welcome-btn button {
  background: #f6ad75 !important;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1b1f22;
  border: 1px solid #f6ad75;
}
/* .welcome-popup .welcome-btn {
  position: absolute;
  bottom: 40px;
} */
.welcome-popup .modal-body {
  position: relative;
}

.winner-popup .modal-content {
  margin-top: 0px;
  background-color: #16212c !important;
  min-height: 20vh;
}
.winner-img h2 {
  color: white;
  font-weight: 500;
  font-size: 25px;
  text-align: center;
}

.payouts-game {
  color: #7c8fa0;
  font-size: 12px;
  max-width: 100%;
  background: #16212c;
  max-width: 200px;
  /* background: linear-gradient(180deg, #1f2f3c, #16212c); */
  background: linear-gradient(180deg, #001a47, #03051a);
  padding: 10px;
  width: 100%;
  border-radius: 5px;
}
/* .payouts-game {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
}
.payout-box {
  display: flex;
  justify-content: space-between;
  padding: 2px 0px;
  width: 20%
} */
.payout-box {
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
}
.payout-box span:last-child {
  color: #fff;
}
/* .payout-box span:last-child{
  font-weight: 600 !important;
  color: #eb2958 !important;
  font-size: 14px !important;
} */

.bottom-table .table-responsive table tr td img {
  height: 22px;
  width: 22px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}
.payout-section .bet h6 img {
  border-radius: 50%;
  margin-left: 5px;
}
.roulette-bet-table table.bet-table .red {
  position: relative;
  border: 1px solid #f1698a;
  background-image: linear-gradient(-180deg, #eb2958 0%, #eb2958 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(235 41 88) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(141 4 38) inset;
}

.roulette-bet-table table.bet-table .red::before {
  content: "";
  display: block;
  height: 0.25rem;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.roulette-bet-table table.bet-table .red::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.roulette-bet-table table.bet-table .black {
  position: relative;
  border: 1px solid #000000;
  background-image: linear-gradient(-180deg, #000000 0%, #000000 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(255 255 255 / 0%),
    0 -0.25rem 1.5rem rgb(0 0 0) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(0 0 0) inset;
}

.roulette-bet-table table.bet-table .black::before {
  content: "";
  display: block;
  height: 0.25rem;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.roulette-bet-table table.bet-table .black::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

/* .chips-box-list .bet-box {
  background-image: linear-gradient(-180deg, #121b24 0%, #121b24 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(18 27 36) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(18 27 36) inset;
} */
.bet-box.red {
  background-image: linear-gradient(-180deg, #eb2958 0%, #eb2958 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(235 41 88) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(141 4 38) inset;
}
.bet-box.black {
  background-image: linear-gradient(-180deg, #000000 0%, #000000 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(255 255 255 / 0%),
    0 -0.25rem 1.5rem rgb(0 0 0) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(0 0 0) inset;
}
.roulette-bet-table table.bet-table .green {
  position: relative;
  border: 1px solid #419d3f;
  background-image: linear-gradient(-180deg, #419e3f 0%, #419e3f 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(255 255 255 / 0%),
    0 -0.25rem 1.5rem rgb(61 148 59) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(156 254 156) inset;
}

.roulette-bet-table table.bet-table .green::before {
  content: "";
  display: block;
  height: 0.25rem;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.roulette-bet-table table.bet-table .green::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.roulette-game-page .winner-section {
  width: 80%;
  margin-top: 50px;
  margin-left: auto;
  margin-right: 50px;
}
.roulette-wrapper {
  background: url("../../assets//images/roulette/home-bg4.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
}

.hover-class {
  background: #0d2b57 !important;
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(47 44 89) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 37%) inset,
    0 0.25rem 0.5rem 0 rgb(23 43 88) inset !important;
}
.active-class {
  background: #0d2b57 !important;
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(47 44 89) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 37%) inset,
    0 0.25rem 0.5rem 0 rgb(23 43 88) inset !important;
}
.bottom-table {
  padding-bottom: 50px;
}
.winner-user-section {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 9;
  /* z-index: 999999; */
  background: linear-gradient(180deg, #1f2f3c, #16212c);
  padding: 15px;
  border-radius: 5px;
}

.winner-user-section h2 {
  font-size: 12px !important;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 0px;
}
.winner-user-section h2 p {
  font-size: 22px;
  font-weight: 700;
  margin-top: 10px;
}
.back-drop-shadow:before {
  content: "";
  height: 100%;
  width: 100%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 99;
  background: #00000080;
}
.roulette-wrapper-container {
  z-index: 99;
  /* position: relative; */
  padding-bottom: 50px;
}

.active-bet-box {
  border: 1px solid #f6ad75;
}
h4.bet-amount {
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
}
h6.bet-heading {
  color: #9893cb;
  line-height: 22px;
  font-size: 12px;
}

.user-bets-table {
  height: 200px;
  overflow-y: scroll;
}

.userWin {
  position: absolute;
  top: -18%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 999999;
  background: #00143b;
  padding: 15px;
  /* border-radius: 5px; */
}

.payout-bet-section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.bet-revert-section {
  justify-content: space-between;
  max-width: 200px;
  align-items: center;
  margin-right: 18px;
  display: flex;
  border-radius: 5px;
  padding: 8px 20px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #dd2856;
}
/* .revert-bet {
  justify-content: space-between !important;
} */

.bet-revert-icon {
  border: 1px solid #fff;
  border-radius: 50%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  cursor: pointer;
  transition: all 0.5s;
}

.bet-revert-icon i {
  color: #fff;
  font-size: 16px;
  transition: all 0.5s;
}
.bet-revert-icon:hover {
  /* border-color: #eb2958; */
}

.bet-revert-section button {
  width: 300px;
  margin: 0 10px;
  background: #eb2958;
  border: 1px solid #eb2958;
  border-radius: 5px;
  transition: all 0.5s;
  -webkit-box-shadow: 16px 41px 187px 9px rgba(255, 255, 255, 0.4);
  -moz-box-shadow: 16px 41px 187px 9px rgba(255, 255, 255, 0.4);
  box-shadow: 16px 41px 187px 9px rgba(255, 255, 255, 0.4);
}

.bet-revert-section button:disabled {
  background: #eb2958;
  border: 1px solid #eb2958;
}

.bet-revert-section button:hover {
  background: #1d2d39;
  border: 1px solid #243746;
}

.bet-total-container {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.bet-total-container-child {
  display: flex;
  /* width: 200px; */
  align-items: center;
  justify-content: space-between;
}
.bet-total-container-child h6, .bet-total-container-child h4{
  margin: 0px!important;
}
tr.pointer-cursor {
  cursor: pointer;
}
.modal {
  z-index: 999999 !important;
}
.user-pic {
  display: flex;
  align-items: center;
}

.user-pic img {
  margin-right: 10px;
}

.stastics {
  color: #7c8fa0;
  font-size: 12px;
  max-width: 239px;
  background: #16212c;
  width: 239px;
  background: linear-gradient(180deg, #001a47, #03051a);
  padding: 10px;
  border-radius: 5px;
}

.stat-flex {
  display: flex;
  justify-content: space-between;
}

.hot-cold-box {
  padding: 10px;
}

.hot-cold-box span {
  color: #fff;
}

.stat-bet-section {
  display: flex;
  justify-content: center;
  max-width: 50%;
  padding: 5px;
  padding-left: 24px;
  border-radius: 5px;
}

.stat-bet-section .bet-box {
  background: #121b24;
  margin: 5px;
  height: 25px;
  width: 25px;
  text-align: center;
  border-radius: 3px;
  font-size: 12px;
  line-height: 12px;
  padding: 6px;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

.stat-select {
  background-color: transparent;
  color: #aaa;
  outline: none;
  border: none;
}

.stat-meter {
  margin: 0 4px;
  height: 30px;
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}

.stat-meter-dark {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  padding: 5px;
  border: 1px solid #000000;
  background-image: linear-gradient(-180deg, #000000 0%, #000000 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(255 255 255 / 0%),
    0 -0.25rem 1.5rem rgb(0 0 0) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(0 0 0) inset;
}

.stat-meter-red {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  padding: 5px;
  border: 1px solid #f1698a;
  background-image: linear-gradient(-180deg, #eb2958 0%, #eb2958 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(235 41 88) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(141 4 38) inset;
}

.stat-meter-green {
  padding: 5px 0px;
  color: #fff;
  border: 1px solid #419d3f;
  background-image: linear-gradient(-180deg, #419e3f 0%, #419e3f 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(255 255 255 / 0%),
    0 -0.25rem 1.5rem rgb(61 148 59) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(156 254 156) inset;
}

.stat-meter-odd {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  padding: 5px;
  border: 1px solid #1a0e11;
  background-image: linear-gradient(-180deg, #1b1013 0%, #0e090a 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(27, 15, 18) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(12, 5, 7) inset;
}

.main-game-list .mobile-view-button {
  display: none;
}

.small-bet-section {
  display: none;
}
img.btc-img {
  height: 20px;
  width: 20px;
  margin-left: 5px;
  border-radius: 50%;
}
/********** statistic section css ***************/
.statistic-section {
  width: 100%;
  margin-top: 25px;
}

.statistic-section .stastics {
  width: 100% !important;
  max-width: 100% !important;
  margin-bottom: 0px;
  padding: 0px;
}
.left-statistic-section {
  width: 40%;
}

.right-statistic-section {
  width: 55%;
}

.stastics {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.left-statistic-section .stat-flex.hot-cold-box {
  padding: 0px !important;
}
.left-hot-number {
  width: 50%;
}

.right-hot-number {
  width: 50%;
}

.left-hot-number .stat-bet-section {
  justify-content: flex-start;
  width: 100%;
  max-width: 100% !important;
  padding-left: 0px !important;
}

.right-hot-number .stat-bet-section {
  max-width: 100%;
  justify-content: flex-end;
  padding: 5px 0px;
}
.dozens-list {
  display: flex;
  justify-content: space-between;
}
.dozens-list h6 {
  font-size: 12px;
  color: white;
}
.statistics-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statistics-options h6 {
  color: white;
}
.statistic-section {
  background: linear-gradient(180deg, #001a47, #03051a);
  padding: 20px;
}
.right-statistic-section h6,
.right-statistic-section p {
  margin-bottom: 5px;
}
.left-statistic-section h6,
.left-statistic-section p {
  margin-bottom: 5px;
}
.statistic-section .stastics {
  background: transparent !important;
  margin-top: 15px;
}
.statistic-section .stat-select {
  font-size: 12px;
  cursor: pointer;
}
.left-statistic-section h4,
.right-statistic-section h4 {
  color: white;
  font-weight: 600;
  font-size: 12px;
}
.stat-bet-section .bet-box:first-child {
  margin-left: 0px !important;
}
.right-hot-number .bet-box:last-child {
  margin-right: 0px !important;
}
.header.sticky {
  background: linear-gradient(180deg, #001a47, #03051a);
}
/**********chips css ************/
/* td.selected-area-zero
.chips-section svg {
  position: absolute;
  height: 30px;
  width: 30px;
  transform: translateX(-50%);
  top: 0px;
  border-radius: 50%;
} */
.chips-section {
  position: absolute;
  background: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin: 0px auto;
  border-radius: 50%;
}
/* .grid-bet-table .chips-section svg {
  position: absolute;
  height: 30px;
  width: 30px;
  transform: translateX(-50%);
  top: 0;
  left: 15px;
} */
/* .grid-bet-table .color-selection .chips-section svg {
  position: absolute;
  height: 30px;
  width: 30px !important;
  transform: translate(50%, 50%) !important;
  top: -15px;
  left: -15px;
} */
.bet-table tr td .chips-section {
  position: absolute;
  background: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin: 0px auto;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 9;
  top: 50%;
  border-radius: 50%;
}
.chips-section {
  border-radius: 50%;
}
.chips-section svg {
  border-radius: 50%;
}
td.selected-area-zero,
.selected-area {
  position: relative;
}
.pagination button,
.pagination button:hover,
.pagination button:focus {
  background: #212e3b;
  border-radius: 3px;
  border-color: #212e3b;
  min-width: 143px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #7c8fa0;
  padding: 11px 15px;
  text-align: left;
  position: relative;
  box-shadow: none;
}
.pagination button:last-child img {
  margin-left: 10px;
  position: absolute;
  right: 18px;
  top: 14px;
}

.pagination button:first-child img {
  margin-right: 10px;
  position: absolute;
  left: 18px;
  top: 14px;
  transform: rotate(180deg);
}

.pagination button:first-child {
  text-align: right;
}

.pagination button.disabled {
  opacity: 0.5;
  background: #212e3b;
  border-color: #212e3b;
  pointer-events: none;
  color: #7c8fa0;
}
.pagination-chk {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.pagination ul li {
  margin: 5px 10px;
  color: #f6ad75;
  font-size: 14px !important;
}

.pagination .pagination__link--disabled {
  background: transparent !important;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 14px !important;
  cursor: not-allowed;
  border: 1px solid #f6ad75;
  opacity: 0.8;
}

.pagination .pagination__link--disabled a {
  color: white !important;
  font-weight: 400;
}

.pagination li.next,
.pagination li.previous {
  background: #f6ad75;
  padding: 8px 25px;
  border-radius: 5px;
  min-width: 80px;
  text-align: center;
}

.pagination li.next a,
.pagination li.previous a {
  color: black;
  font-weight: 400;
}

.pagination li.pagination__link--active {
  color: white !important;
}
.pagination a {
  text-decoration: none !important;
}
.pagination li.next.pagination__link--disabled,
li.previous.pagination__link--disabled {
  display: none;
}

.crypto-select {
  width: 200px;
}

.custom-select-option {
  position: relative;
}

.custom-select-option h6 {
  background: transparent;
  padding: 5px 0px;
  border-radius: 5px;
  min-height: 35px;
  display: flex;
  align-items: center;
  /* border: 1px solid #0c2044; */
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
}
.select-balance {
  display: flex;
  margin-right: 5px;
}
.select-option {
  background: #001a46;
  padding: 5px;
  border-radius: 5px;
  max-height: 140px;
  overflow: auto;
  position: absolute;
  max-width: 100%;
  width: 100%;
  top: 37px;
}

.select-option p {
  margin-bottom: 0px;
  padding: 10px 5px;
  color: white;
  font-size: 14px;
  border-bottom: 1px solid #091b58;
  cursor: pointer;
}

.select-option::-webkit-scrollbar {
  width: 5px;
}

.select-option::-webkit-scrollbar-track {
  background: #181d22;
}

.select-option::-webkit-scrollbar-thumb {
  background: #222c37;
}
.select-option p img {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  border-radius: 50%;
  object-fit: cover;
}
.custom-select-option img.icon-img {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  border-radius: 50%;
  object-fit: cover;
}

.custom-select-option h6 img {
  height: 20px;
  cursor: pointer;
}

.custom-select-option h6 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

/* .landing-featured-list-content ul li {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.chips-section .chips-box {
  position: relative;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

.chips-section .chips-box > * {
  display: block;
  margin-top: -29px;
}

.chips-section .chips-box > *:first-child {
  margin-top: 0px;
}

.chips-box {
  position: relative;
}
.bet-total-container .fa-info-circle {
  margin-left: 10px;
  cursor: pointer;
}
.wallet-input a img {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  width: 25px;
  object-fit: cover;
}
.wallet-input a.dropdown-item {
  padding: 10px;
  color: white;
}
.wallet-input a.dropdown-item:hover {
  background-color: transparent !important;
}
.wallet-input button:focus {
  outline: none;
  box-shadow: none;
}
.limit-sec h6 {
  color: #fff;
  font-size: 12px;
}
.limit-sec .fa-info-circle {
  margin-right: 10px;
  cursor: pointer;
}
.limit-sec {
  padding: 0px 4px;
}
.add-sidebar body {
  overflow: hidden;
}

.add-sidebar .small-bet-section.open-bet-section {
  top: 0px;
  z-index: 9999999 !important;
}
/* .place-bet-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.place-bet-heading .bet-coins {
  width: 100%;
}
.revert-bet-section {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  width: 100%;
  /* background: #fe2247; */
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  margin-top: 25px;
}
.revert-bet-section img {
  height: 21px;
  cursor: pointer;
}

.revert-bet-section span {
  border: none !important;
}
/* .roulette-table-section .bet-section {
  display: none;
} */
.statistic-section {
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
}
.welcome-popup .modal-content {
  border: none;
}

.revert-bet-section {
  display: flex;
  max-width: 213px;
  justify-content: space-between;
  width: 100%;
  background: #3d3d3d;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  margin-top: 0;
  position: absolute;
  top: 0px;
  transform: translate(-50%, -50%);
  left: 50%;
  border: 1px solid #eb2958;
  background-image: linear-gradient(-180deg, #ea4d74 0%, #e32f5a 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(255 255 255 / 0%),
    0 -0.25rem 1.5rem rgb(234 42 88) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(236 79 117) inset;
}
.select-chips-section {
  position: relative;
}

.rolulette-right-table-section::-webkit-scrollbar {
  height: 5px;
}

.rolulette-right-table-section::-webkit-scrollbar-track {
  background: #030921;
}

.rolulette-right-table-section::-webkit-scrollbar-thumb {
  background: #001a47;
}
.welcome-popup .welcome-btn {
  padding-bottom: 30px;
}
.bet-coins {
  padding-bottom: 15px;
}
.bet-total-container-child {
  padding: 5px 0px;
}
.bet-box img {
  height: 35px !important;
  width: 35px !important;
}

.hidden-body {
  overflow: hidden;
}
.md-ddnone.classname {
  padding-top: 75px;
}

.userWin {
  position: fixed;
  top: -18%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 999999;
  background: #071b43;
  padding: 15px;
  /* border-radius: 5px; */
  width: 100%;
  height: fit-content;
  top: 50%;
  display: flex;
  justify-content: center;
  border-top: 1px solid #40250c;
  align-items: center;
  max-width: 400px;
  border-bottom: 1px solid #3d240c;
  background: linear-gradient(to right, #000000db, #2a321e);
}
.overlay {
  position: fixed;
  top: -18%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 999999;
  background: #64646480;
  padding: 15px;
  /* border-radius: 5px; */
  width: 100%;
  height: 100%;
  top: 50%;
  display: flex;
  justify-content: center;
}
.userWin h2 {
  color: rgb(6, 175, 14);
  font-size: 35px;
}
.user-loser h2 {
  color: red;
  font-size: 35px;
}
.user-loser {
  background: linear-gradient(to right, #071b43db, #432607);
}
.winner-img {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.winner-img img {
  height: 300px;
}

/******* leave confirmation popup *********/
.leave-confirm .modal-content {
  background: #1b1f2e;
  color: #fff;
}

.leave-confirm .modal-header {
  border: none;
}

.leave-confirm .modal-content .block {
  text-align: center;
}

.leave-confirm .modal-content .block p {
  font-size: 22px;
  max-width: 350px;
  margin: 0px auto;
  padding-bottom: 40px;
}

.block-view h4 {
  margin: 0;
  padding-bottom: 20px;
  font-size: 22px;
}

.leave-confirm .modal-content .modal-body {
  padding: 60px 30px 60px;
}

.leave-confirm button.btn.btn-primary:first-child {
  padding: 8px 20px !important;
  font-size: 15px;
  background: #f6ad75;
  border-radius: 3px;
  border: 1px solid #f6ad75;
  color: black;
}

.leave-confirm button.btn.btn-primary:last-child {
  padding: 8px 20px !important;
  font-size: 15px;
  background: transparent;
  border-radius: 3px;
  border: 1px solid #f6ad75;
  color: #f6ad75 !important;
}
.bottom-table table tr td button, .bottom-table table tr td button:hover, .bottom-table table tr td button:focus, 
.bottom-table table tr td .btn-warning:not(:disabled):not(.disabled):active
{
  padding: 8px 20px !important;
  font-size: 15px;
  background: transparent;
  border-radius: 5px;
  border: 1px solid #f6ad75;
  color: #f6ad75 !important;
  box-shadow: none;
  outline: none;
}
.leave-confirm .modal-content {
  border: none !important;
  background: linear-gradient(180deg, #001a47, #03051a) !important;
}
.leave-confirm .sub-btn button {
  margin: 0 10px;
}

/******* animation *********/

.winning-animation-win > .before,
.winning-animation-win > .after {
  z-index: 9999999999;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.winning-animation-win > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: 183px 79.3333333333px #ffe100, -36px 15.3333333333px #15ff00,
      13px -55.6666666667px #00ff59, -29px -405.6666666667px #ff008c,
      219px -44.6666666667px #ff6f00, -160px -190.6666666667px #00ff4d,
      -67px -273.6666666667px #00ff6f, -22px -215.6666666667px #9500ff,
      205px -28.6666666667px #fbff00, 37px 55.3333333333px #00fbff,
      37px -232.6666666667px #ff004d, 3px -66.6666666667px #006aff,
      115px -342.6666666667px #0073ff, -106px 2.3333333333px #2200ff,
      -3px -411.6666666667px #00d9ff, -217px -223.6666666667px darkorange,
      107px -200.6666666667px #00ff51, 88px -387.6666666667px #ff0009,
      196px 24.3333333333px #0400ff, 211px -314.6666666667px #5eff00,
      -46px -289.6666666667px #00ff26, 69px -404.6666666667px #fbff00,
      -30px -326.6666666667px #ffaa00, 69px 62.3333333333px #00ffe1,
      -194px -360.6666666667px #ffa600, 154px -372.6666666667px #003cff,
      -182px 43.3333333333px #ff3300, -220px -406.6666666667px #a200ff,
      -67px -81.6666666667px red, -168px 43.3333333333px #ff4800,
      -75px -102.6666666667px #00aeff, 180px -89.6666666667px #002fff,
      52px 53.3333333333px #4dff00, 125px -211.6666666667px #a600ff,
      -48px -80.6666666667px #37ff00, -242px -313.6666666667px #0044ff,
      -81px -115.6666666667px #00ff8c, 224px -196.6666666667px #d5ff00,
      89px -102.6666666667px #00ff11, -59px -247.6666666667px #00d5ff,
      -90px -101.6666666667px #0088ff, 151px -223.6666666667px #66ff00,
      113px -45.6666666667px #00ffbb, 79px -409.6666666667px #2bff00,
      48px -123.6666666667px #a200ff, -209px 82.3333333333px #e1ff00,
      134px -0.6666666667px #00ffc8, 73px -139.6666666667px #00aaff,
      -126px -87.6666666667px #6200ff, 236px -237.6666666667px #ff6a00,
      -230px -246.6666666667px #ff4400;
  }
}
@-moz-keyframes bang {
  to {
    box-shadow: 183px 79.3333333333px #ffe100, -36px 15.3333333333px #15ff00,
      13px -55.6666666667px #00ff59, -29px -405.6666666667px #ff008c,
      219px -44.6666666667px #ff6f00, -160px -190.6666666667px #00ff4d,
      -67px -273.6666666667px #00ff6f, -22px -215.6666666667px #9500ff,
      205px -28.6666666667px #fbff00, 37px 55.3333333333px #00fbff,
      37px -232.6666666667px #ff004d, 3px -66.6666666667px #006aff,
      115px -342.6666666667px #0073ff, -106px 2.3333333333px #2200ff,
      -3px -411.6666666667px #00d9ff, -217px -223.6666666667px darkorange,
      107px -200.6666666667px #00ff51, 88px -387.6666666667px #ff0009,
      196px 24.3333333333px #0400ff, 211px -314.6666666667px #5eff00,
      -46px -289.6666666667px #00ff26, 69px -404.6666666667px #fbff00,
      -30px -326.6666666667px #ffaa00, 69px 62.3333333333px #00ffe1,
      -194px -360.6666666667px #ffa600, 154px -372.6666666667px #003cff,
      -182px 43.3333333333px #ff3300, -220px -406.6666666667px #a200ff,
      -67px -81.6666666667px red, -168px 43.3333333333px #ff4800,
      -75px -102.6666666667px #00aeff, 180px -89.6666666667px #002fff,
      52px 53.3333333333px #4dff00, 125px -211.6666666667px #a600ff,
      -48px -80.6666666667px #37ff00, -242px -313.6666666667px #0044ff,
      -81px -115.6666666667px #00ff8c, 224px -196.6666666667px #d5ff00,
      89px -102.6666666667px #00ff11, -59px -247.6666666667px #00d5ff,
      -90px -101.6666666667px #0088ff, 151px -223.6666666667px #66ff00,
      113px -45.6666666667px #00ffbb, 79px -409.6666666667px #2bff00,
      48px -123.6666666667px #a200ff, -209px 82.3333333333px #e1ff00,
      134px -0.6666666667px #00ffc8, 73px -139.6666666667px #00aaff,
      -126px -87.6666666667px #6200ff, 236px -237.6666666667px #ff6a00,
      -230px -246.6666666667px #ff4400;
  }
}
@-o-keyframes bang {
  to {
    box-shadow: 183px 79.3333333333px #ffe100, -36px 15.3333333333px #15ff00,
      13px -55.6666666667px #00ff59, -29px -405.6666666667px #ff008c,
      219px -44.6666666667px #ff6f00, -160px -190.6666666667px #00ff4d,
      -67px -273.6666666667px #00ff6f, -22px -215.6666666667px #9500ff,
      205px -28.6666666667px #fbff00, 37px 55.3333333333px #00fbff,
      37px -232.6666666667px #ff004d, 3px -66.6666666667px #006aff,
      115px -342.6666666667px #0073ff, -106px 2.3333333333px #2200ff,
      -3px -411.6666666667px #00d9ff, -217px -223.6666666667px darkorange,
      107px -200.6666666667px #00ff51, 88px -387.6666666667px #ff0009,
      196px 24.3333333333px #0400ff, 211px -314.6666666667px #5eff00,
      -46px -289.6666666667px #00ff26, 69px -404.6666666667px #fbff00,
      -30px -326.6666666667px #ffaa00, 69px 62.3333333333px #00ffe1,
      -194px -360.6666666667px #ffa600, 154px -372.6666666667px #003cff,
      -182px 43.3333333333px #ff3300, -220px -406.6666666667px #a200ff,
      -67px -81.6666666667px red, -168px 43.3333333333px #ff4800,
      -75px -102.6666666667px #00aeff, 180px -89.6666666667px #002fff,
      52px 53.3333333333px #4dff00, 125px -211.6666666667px #a600ff,
      -48px -80.6666666667px #37ff00, -242px -313.6666666667px #0044ff,
      -81px -115.6666666667px #00ff8c, 224px -196.6666666667px #d5ff00,
      89px -102.6666666667px #00ff11, -59px -247.6666666667px #00d5ff,
      -90px -101.6666666667px #0088ff, 151px -223.6666666667px #66ff00,
      113px -45.6666666667px #00ffbb, 79px -409.6666666667px #2bff00,
      48px -123.6666666667px #a200ff, -209px 82.3333333333px #e1ff00,
      134px -0.6666666667px #00ffc8, 73px -139.6666666667px #00aaff,
      -126px -87.6666666667px #6200ff, 236px -237.6666666667px #ff6a00,
      -230px -246.6666666667px #ff4400;
  }
}
@-ms-keyframes bang {
  to {
    box-shadow: 183px 79.3333333333px #ffe100, -36px 15.3333333333px #15ff00,
      13px -55.6666666667px #00ff59, -29px -405.6666666667px #ff008c,
      219px -44.6666666667px #ff6f00, -160px -190.6666666667px #00ff4d,
      -67px -273.6666666667px #00ff6f, -22px -215.6666666667px #9500ff,
      205px -28.6666666667px #fbff00, 37px 55.3333333333px #00fbff,
      37px -232.6666666667px #ff004d, 3px -66.6666666667px #006aff,
      115px -342.6666666667px #0073ff, -106px 2.3333333333px #2200ff,
      -3px -411.6666666667px #00d9ff, -217px -223.6666666667px darkorange,
      107px -200.6666666667px #00ff51, 88px -387.6666666667px #ff0009,
      196px 24.3333333333px #0400ff, 211px -314.6666666667px #5eff00,
      -46px -289.6666666667px #00ff26, 69px -404.6666666667px #fbff00,
      -30px -326.6666666667px #ffaa00, 69px 62.3333333333px #00ffe1,
      -194px -360.6666666667px #ffa600, 154px -372.6666666667px #003cff,
      -182px 43.3333333333px #ff3300, -220px -406.6666666667px #a200ff,
      -67px -81.6666666667px red, -168px 43.3333333333px #ff4800,
      -75px -102.6666666667px #00aeff, 180px -89.6666666667px #002fff,
      52px 53.3333333333px #4dff00, 125px -211.6666666667px #a600ff,
      -48px -80.6666666667px #37ff00, -242px -313.6666666667px #0044ff,
      -81px -115.6666666667px #00ff8c, 224px -196.6666666667px #d5ff00,
      89px -102.6666666667px #00ff11, -59px -247.6666666667px #00d5ff,
      -90px -101.6666666667px #0088ff, 151px -223.6666666667px #66ff00,
      113px -45.6666666667px #00ffbb, 79px -409.6666666667px #2bff00,
      48px -123.6666666667px #a200ff, -209px 82.3333333333px #e1ff00,
      134px -0.6666666667px #00ffc8, 73px -139.6666666667px #00aaff,
      -126px -87.6666666667px #6200ff, 236px -237.6666666667px #ff6a00,
      -230px -246.6666666667px #ff4400;
  }
}
@keyframes bang {
  to {
    box-shadow: 183px 79.3333333333px #ffe100, -36px 15.3333333333px #15ff00,
      13px -55.6666666667px #00ff59, -29px -405.6666666667px #ff008c,
      219px -44.6666666667px #ff6f00, -160px -190.6666666667px #00ff4d,
      -67px -273.6666666667px #00ff6f, -22px -215.6666666667px #9500ff,
      205px -28.6666666667px #fbff00, 37px 55.3333333333px #00fbff,
      37px -232.6666666667px #ff004d, 3px -66.6666666667px #006aff,
      115px -342.6666666667px #0073ff, -106px 2.3333333333px #2200ff,
      -3px -411.6666666667px #00d9ff, -217px -223.6666666667px darkorange,
      107px -200.6666666667px #00ff51, 88px -387.6666666667px #ff0009,
      196px 24.3333333333px #0400ff, 211px -314.6666666667px #5eff00,
      -46px -289.6666666667px #00ff26, 69px -404.6666666667px #fbff00,
      -30px -326.6666666667px #ffaa00, 69px 62.3333333333px #00ffe1,
      -194px -360.6666666667px #ffa600, 154px -372.6666666667px #003cff,
      -182px 43.3333333333px #ff3300, -220px -406.6666666667px #a200ff,
      -67px -81.6666666667px red, -168px 43.3333333333px #ff4800,
      -75px -102.6666666667px #00aeff, 180px -89.6666666667px #002fff,
      52px 53.3333333333px #4dff00, 125px -211.6666666667px #a600ff,
      -48px -80.6666666667px #37ff00, -242px -313.6666666667px #0044ff,
      -81px -115.6666666667px #00ff8c, 224px -196.6666666667px #d5ff00,
      89px -102.6666666667px #00ff11, -59px -247.6666666667px #00d5ff,
      -90px -101.6666666667px #0088ff, 151px -223.6666666667px #66ff00,
      113px -45.6666666667px #00ffbb, 79px -409.6666666667px #2bff00,
      48px -123.6666666667px #a200ff, -209px 82.3333333333px #e1ff00,
      134px -0.6666666667px #00ffc8, 73px -139.6666666667px #00aaff,
      -126px -87.6666666667px #6200ff, 236px -237.6666666667px #ff6a00,
      -230px -246.6666666667px #ff4400;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

.winning-animation {
  position: fixed;
  z-index: 999999;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
}
.winning-animation:before {
  content: "";
  position: absolute;
  background: #000000cc;
  height: 100%;
  width: 100%;
  z-index: 999999;
}
.winning-animation .winning-amount {
  color: white;
  z-index: 9999999999;
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  top: 50%;
  left: 50%;
  font-size: 40px;
  color: #f7f7f7;
  padding: 41px 20px;
  max-width: 600px;
  width: 100%;
  text-align: center;
  background-image: linear-gradient(to right, #eb295800, #eb2958, #eb295800);
  animation: neon1 0.5s ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes neon1 {
  from {
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}
.winning-animation .winning-amount h2 {
  color: white;
  font-size: 48px;
  text-align: center;
  font-family: 'Righteous', cursive !important;
  margin-bottom: 10px;
}

.winning-amount p {
  font-size: 16px;
  margin: 0;
  color: white;
  font-weight: 400;
}
.close-img img {
  position: absolute;
  z-index: 9999999;
  height: 22px;
  right: 16px;
  top: 15px;
  cursor: pointer;
}
.stat-meter-red,
.stat-meter-green,
.stat-meter-dark,
.stat-meter-odd {
  min-width: fit-content;
}
.winning-animation-win .winning-amount {
  background-image: linear-gradient(to right, #337a1700, #337a17, #337a1700);
}

.winns img {
  width: 40px;
  border-radius: 100%;
  margin-right: 10px;
  height: 40px;
}
.flex-win {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: 60%;
  margin: 5px auto;
  font-size: 15px;
}

.flex-win img {
  height: 18px;
  width: 18px;
  margin-left: 5px;
}
.flex-win span {
  width: 40%;
  display: flex;
  align-items: center;
}

/***** slot result *********/
.left-slot-result {
  width: 40%;
}

.right-slot-result {
  width: 60%;
}

.right-slot-result img {
  height: 50px !important;
  width: 100% !important;
  object-fit: contain;
}

.slot-result-section {
  display: flex;
  margin: 20px 0px;
}

.slot-images {
  display: flex;
  margin-bottom: 10px;
}

.slot-numbers {
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.winner-section.last-result{
  margin-top: 10px !important;
}
.winner-section.last-result p {
  color: white;
  font-size: 14px;
  font-weight: 400;
}
.winner-section.last-result .result-listing {
  display: flex;
  /* border-top: 1px dashed #434343; */
  padding: 3px 0px;
  margin: 5px 0px;
  width: 40%;
  align-items: center;
}

.winner-section.last-result .result-listing p {
  width: 50%;
  margin: 0;
  font-size: 12px;
}

.winner-section.last-result .result-listing span {
  color: #b9b9b9;
  width: 50%;
  text-align: right;
  font-size: 12px;
}
.result-listiing-section{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}