@media screen and (min-width: 1440px) {
  .container {
    max-width: 1400px !important;
  }
}
@media screen and (min-width: 991px) {
  .md-ddnone {
    display: none !important;
  }
  .roulette-game-page .select-chips-section h6 > span {
    display: none;
  }
}

@media screen and (max-width: 1366px) {
  .container {
    max-width: 1220px !important;
  }
  .rolulette-right-section {
    width: 60%;
  }
  .roulette-game-page .roulette-table-section {
    width: 40%;
  }
  .roulette-bet-table table.bet-table td {
    font-size: 12px;
    height: 40px;
    width: 28px;
  }
  .roulette-bet-table table.bet-table td:last-child {
    font-size: 12px;
  }
  .roulette-bet-table .grid-bet-table > div {
    font-size: 12px;
  }
  .roulette-game-page .select-chips-section h6 {
    font-size: 12px;
  }
  .roulette-bet-table table.bet-table tr td:first-child,
  .roulette-bet-table table.bet-table tr td:last-child {
    width: 35px;
  }
  td.selected-area-zero {
    width: 3px !important;
  }
  td.selected-area {
    width: 3px !important;
  }
}
@media screen and (min-width: 991px) {
  .roulette-game-page .winner-section {
    width: 95%;
    margin-top: 50px;
    margin-left: auto;
    margin-right: 0;
  }
  .rolulette-right-section {
    width: 56%;
  }
}
@media (max-width: 1024px) and (min-width: 991px) {
  .rolulette-right-table-section {
    overflow-x: auto;
    padding: 34px 20px !important;
  }
  .rolulette-right-table-section {
    overflow-x: auto;
    padding: 0px 0px 10px !important;
  }
}

@media screen and (max-width: 991px) {
  .payout-textid .bet h6 {
    overflow: hidden;
    padding: 0px 10px !important;
  }
  .bottom-footer {
    padding-bottom: 50px;
  }
  .roulette-bet-table .grid-bet-table {
    font-size: 13px;
    height: 100px;
    width: 393px;
    grid-template-columns: repeat(6, 65.4px);
    left: 23px;
    height: 85px;
    margin-top: -1px;
    left: 19px;
  }
  .roulette-bet-table {
    width: 585px;
  }

  .stat-meter {
    margin: 0 0px;
  }
  td.selected-area,
  td.selected-area-zero {
    width: 1px !important;
  }
  .roulette-bet-table table.bet-table tr td:first-child,
  .roulette-bet-table table.bet-table tr td:last-child {
    width: 15px !important;
  }
  .sm-ddnone {
    display: none !important;
  }
  .select-option {
    position: absolute;
    top: 37px;
    z-index: 9;
  }
  .custom-select-option h6 {
    font-size: 12px;
  }
  .select-option p {
    font-size: 12px;
  }
  .roulette-game-page .roulette-table-section {
    width: 100%;
  }
  .rolulette-right-section {
    width: 100%;
  }
  .rolulette-right-table-section {
    overflow-x: auto;
    padding: 0 0 30px;
    margin: 0 auto 20px;
  }
  .rolulette-right-section .roulette-bet-table {
    /* transform: rotate(90deg);
    margin-top: -246px; */
    margin-bottom: 50px;
  }
  .game-table-section {
    display: inline;
  }
  .roulette-game-page .bottom-table .nav-tabs a {
    width: 33%;
    padding: 10px 25px;
  }
  .bottom-table .table-responsive table tr td,
  .bottom-table .table-responsive table tr th {
    white-space: nowrap;
    padding: 10px 20px;
  }

  .roulette-bet-table table.bet-table td {
    height: 35px;
    width: 13px;
  }

  .roulette-table-section .main {
    max-width: 350px;
    margin: 20px auto 0px;
    position: relative;
    width: 100%;
    margin: -74px auto 0px !important;
  }

  .data-inner span {
    color: #fff;
    font-size: 15px;
  }

  .data .mask,
  .data .result {
    font-size: 15px;
  }
  .bet-revert-section {
    width: 180px;
  }

  .bet-section {
    margin-left: 12px;
    padding: 3px;
    justify-content: space-around;
  }

  .bet-section .bet-box {
    font-size: 10px;
    height: 30px;
    width: 30px;
    line-height: 28px;
  }

  /* .rolulette-right-section {
    display: none;
  } */

  /* .roulette-game-page .winner-section {
    display: none;
  } */

  .roulette-game-page .winner-section {
    width: 100%;
    margin: 10px 0;
    max-width: 550px;
    margin: 15px auto;
  }
  .roulette-bet-table {
    padding-top: 5px;
  }

  .roulette-game-page .select-chips-section {
    padding: 20px 25px !important;
    margin: 0;
  }
  .roulette-game-page .select-chips-section h6 > span {
    float: right;
    padding-right: 10px;
  }
  .roulette-game-page .bet-section {
    background: transparent;
    padding: 0;
  }
  .payout-bet-section {
    justify-content: center;
    width: 90%;
    margin: auto;
  }
  .payouts-game {
    margin-right: 3%;
    /* margin-top: 20px; */
    max-width: 47%;
    width: 47%;
    margin-left: 0;
  }
  /* .bet-revert-section {
    margin-left: 3%;
    margin-right: 0;
    max-width: 47%;
    width: 47%;
  } */

  .btn-sm {
    border-color: #eb2958;
    background-image: linear-gradient(-180deg, #eb2958 0%, #eb2958 100%);
    box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
      0 -0.25rem 1.5rem rgb(235 41 88) inset,
      0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
      0 0.25rem 0.5rem 0 rgb(141 4 38) inset;
    font-size: 12px;
    transition: 0.2s;
    color: white !important;
    font-weight: 500 !important;
  }

  .btn-sm:hover,
  .btn-sm:focus {
    background-color: #eb2958;
    border-color: #97324b;
    box-shadow: none;
    outline: none;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    outline: none;
    background-color: #97324b;
    border-color: #97324b;
  }

  .main-game-list .mobile-view-button {
    display: block;
  }

  .small-bet-section {
    display: block;
    width: 80vw;
    min-height: 100vh;
    /* background: url("assets/images/roulette/home-bg4.png"); */
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background: linear-gradient(180deg, #001a47, #03051a);
    position: fixed;
    z-index: 9999999 !important;
    right: -80vw;
    padding: 15px;
    top: 0px;
    margin: auto 0;
    bottom: 0px;
    transition: 0.5s all;
    height: 100% !important;
  }

  .open-bet-section {
    right: 0vw;
  }

  .small-bet-container .close-bet-section {
    display: flex;
    padding: 30px 10px 0px 20px;
    justify-content: space-between;
    position: relative;
  }

  .close-bet-section span {
    color: #fff;
    font-size: 14px;
  }
  .close-bet-section img {
    width: 21px;
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 15px;
  }
  .statistic-section {
    margin-top: 10%;
    border-radius: 3px;
  }
  .small-place-bet {
    /* height: 130px; */
    background-color: #011338;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .small-place-bet .bet-revert-section {
    width: 100%;
    background: transparent;
    height: auto;
  }

  .small-place-bet .chips-box-list {
    padding: 10px;
  }
  /* .bet-total-container-child {
    width: 39%;
    margin: 0 5px 0 0;
  }
  .bet-total-container-child:last-child {
    width: 59%;
    margin: 0 0 0 5px;
  } */
  .bet-total-container-child .bet-heading,
  .bet-total-container-child .bet-amount {
    font-size: 10px;
  }
  .bet-total-container {
    margin-top: 5px;
  }

  .small-place-bet .chips-box-list .bet-coins .bet-box {
    min-height: 16px;
    min-width: 30px;
    font-size: 9px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .statics-container {
    display: none;
  }

  .small-bet-container {
    position: relative;
    height: 100%;
  }

  /* .roulette-bet-table .grid-bet-table {
		width: 313px;
		grid-template-columns: repeat(6, 51.9px);
		left: 12px;
	} */
  .bet-section {
    margin-left: 5px;
    padding: 3px;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px auto 0;
    max-width: fit-content !important;
    justify-content: center;
  }
  .statistic-section .stastics .left-statistic-section,
  .statistic-section .stastics .right-statistic-section {
    width: 100%;
  }
  .statistic-section .stastics {
    display: block;
  }
  .roulette-game-page .bottom-table .nav-tabs a {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 500;
  }
  .bet-coins {
    width: 100%;
    /* justify-content: center; */
  }
  .chips-box-list {
    display: block;
  }
  .bet-total-container {
    width: 100%;
    padding-left: 10px;
  }
  h6.bet-heading {
    color: #9893cb;
    line-height: initial;
    font-size: 12px;
    margin-bottom: 0px;
  }
  .table-section {
    height: calc(100vh - 270px);
    overflow: auto;
    position: relative;
  }
  .roulette-bet-table table.bet-table tr td:first-child,
  .roulette-bet-table table.bet-table tr td:last-child {
    width: 18px !important;
  }
  .roulette-bet-table table.bet-table td:last-child {
    font-size: 10px;
  }
  /********* Welcome popup ************/
  .welcome-popup .welcome-btn {
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: center;
  }
  .welcome-btn .btn-primary {
    margin: 32px 0px 0;
    width: 150px;
    height: 40px;
    border-radius: 4px;
  }
  .welcome-img h2 {
    font-size: 18px !important;
  }

  .bet-total-container {
    width: 100%;
    padding: 10px 0;
    display: flex;
    /* flex-direction: revert; */
    justify-content: space-between;
  }
  .bet-total-container2 {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  .bet-total-container-child {
    width: 100%;
    margin-right: 0%;
  }
  .bet-total-container-child:last-child {
    width: 100%;
    margin-right: none;
  }
  .bet-total-container-child .bet-heading {
    width: 56%;
    margin-right: 1px;
  }
  .small-bet-section .roulette-bet-table {
    position: absolute;
    padding-top: 0;
    top: 55%;
    transform: translateY(0%) rotate(-90deg);
    left: 0;
  }
  .payout-bet-section {
    display: none;
  }
  .roulette-table-section .bet-section {
    display: flex;
  }

  /* .revert-bet-section {
  justify-content: flex-start;
  width: fit-content;
  margin-top: 0;
} */
  .revert-bet-section {
    justify-content: space-between;
    width: -moz-fit-content;
    width: 100%;
    margin-top: 0;
  }
  .place-bet-heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .roulette-game-page .winner-section {
    margin-top: 35px;
  }
  .bet-total-container-child h6 {
    white-space: nowrap;
  }
  .statistic-section {
    background: linear-gradient(180deg, #03081f, #03051a);
    padding: 20px;
  }
  .sticky-footer img {
    height: 15px !important;
    width: 15px !important;
  }
  .rules-popup .modal-content {
    margin-top: 0px;
}
.popup-btn button{
  width: 45% !important;
}
.rules-popup .nav-tabs a {
  padding: 10px;
}

}
@media screen and (max-width: 480px) {
  .winner-section.last-result .result-listing {
    display: flex;
    /* border-top: 1px dashed #434343; */
    padding: 3px 0px;
    margin: 5px 0px;
    width: 100%;
  }
  .small-bet-section .roulette-bet-table {
    position: absolute;
    padding-top: 0;
    top: 50%;
    transform: translateY(0%) rotate(-90deg);
    left: -50%;
  }
  .roulette-game-page .select-chips-section {
    padding: 20px 15px !important;
  }
  .payouts-game {
    margin-top: 10%;
  }
}
@media screen and (max-width: 380px) {
  .bet-section {
    margin-top: 20px;
  }
  .bet-section .bet-box {
    margin: 5px 2px;
  }
  .popup-btn button {
    width: 45% !important;
  }
  .popup-img img {
    margin: 25px auto 0;
    width: 95px;
    height: 115px;
  }
  .popup-img h2 {
    font-size: 18px;
  }
}
@media screen and (max-width: 375px) {
  .rules-box span {
    font-size: 10px;
    padding: 4px 5px;
    min-width: 55px;
  }
  .chips-box-list .bet-box {
    min-height: 30px;
    min-width: 30px;
    line-height: 30px;
  }
  .sticky-footer button#dropdown-profile {
    font-size: 10px;
  }
  .bet-box img {
    height: 30px !important;
    width: 30px !important;
  }
  .sticky-footer img {
    height: 12px !important;
    width: 12px !important;
  }
  .sticky-footer .dropdown-toggle::after {
    height: 12px;
    width: 12px;
    margin-left: 5px;
  }
  .roulette-table-section .main {
    transform: scale(0.8);
    margin-left: 0px !important;
  }
  /* .small-bet-section .roulette-bet-table {
    transform: rotate(-90deg);
    margin-top: 0;
    height: 100%;
    position: absolute;
    top: 20px;
    transform: translate(-50%, 50%) rotate(-90deg);
    padding-top: 0px;
    left: 75%;
  } */
  .small-bet-section .roulette-bet-table {
    position: absolute;
    padding-top: 0;
    top: 75%;
    transform: translateY(0%) rotate(-90deg);
    left: -50%;
  }

  .winner-user-section {
    margin-left: 0px;
    width: fit-content;
  }
  .rules-popup .nav-tabs a {
    padding: 12px;
    padding: 8px;
  }
  .back-drop-shadow:before {
    z-index: 9;
  }
  .bet-revert-section {
    margin-right: 0px;
    top: -10px;
}
}

@media screen and (max-width: 374px) {
  .plate {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
}
