@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
body {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: #121b24 !important;
}
.modal-body {
  background: linear-gradient(180deg, #001a47, #03051a) !important;
}
.main_content {
  position: relative;
}

.content-wrapper {
  min-height: calc(100vh - 120px);
  margin-top: 0px;
}

.coming-soon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 120px);
  flex-wrap: wrap;
}

.coming-soon-box h2 {
  color: #a9f55c;
  font-weight: 700;
  font-size: 50px;
  letter-spacing: 0.5px;
  margin: 0;
  width: 100%;
}

.coming-soon-box p {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 14px;
  color: #7c8fa0;
  padding-top: 10px;
}

.modal-content {
  z-index: 999999999;
}

/* .header-top-area button#dropdown-profile {
  color: #7c8fa0;
  background-color: transparent;
  border-color: transparent;
  display: flex;
  align-items: center;
} */

/***************** Reset Css *******************/

html,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.game-result {
  color: #fff;
}
/***************** Language *******************/

.language {
  position: absolute;
  right: 10px;
  top: 10px;
}

.language select {
  background: #000000;
  border-color: #282828;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 20px;
  border-radius: 30px;
  cursor: pointer;
}

.language select:focus {
  outline: none;
}

.bottom-table {
  margin-top: 50px;
}
/***************** Roulette *******************/

.roulette-wrapper {
  padding-top: 110px;
}

.spin-btns {
  position: absolute;
  left: 0;
  top: 0;
}

.number:nth-child(1) {
  -webkit-transform: rotateZ(9.4736842deg);
          transform: rotateZ(9.4736842deg);
}
.number:nth-child(2) {
  -webkit-transform: rotateZ(18.9473684deg);
          transform: rotateZ(18.9473684deg);
}
.number:nth-child(3) {
  -webkit-transform: rotateZ(28.4210526deg);
          transform: rotateZ(28.4210526deg);
}
.number:nth-child(4) {
  -webkit-transform: rotateZ(37.8947368deg);
          transform: rotateZ(37.8947368deg);
}
.number:nth-child(5) {
  -webkit-transform: rotateZ(47.368421deg);
          transform: rotateZ(47.368421deg);
}
.number:nth-child(6) {
  -webkit-transform: rotateZ(56.8421052deg);
          transform: rotateZ(56.8421052deg);
}
.number:nth-child(7) {
  -webkit-transform: rotateZ(66.3157894deg);
          transform: rotateZ(66.3157894deg);
}
.number:nth-child(8) {
  -webkit-transform: rotateZ(75.7894736deg);
          transform: rotateZ(75.7894736deg);
}
.number:nth-child(9) {
  -webkit-transform: rotateZ(85.263158deg);
          transform: rotateZ(85.263158deg);
}
.number:nth-child(10) {
  -webkit-transform: rotateZ(94.7368422deg);
          transform: rotateZ(94.7368422deg);
}
.number:nth-child(11) {
  -webkit-transform: rotateZ(104.21052639deg);
          transform: rotateZ(104.21052639deg);
}
.number:nth-child(12) {
  -webkit-transform: rotateZ(113.6842106deg);
          transform: rotateZ(113.6842106deg);
}
.number:nth-child(13) {
  -webkit-transform: rotateZ(123.1578948deg);
          transform: rotateZ(123.1578948deg);
}
.number:nth-child(14) {
  -webkit-transform: rotateZ(132.631579deg);
          transform: rotateZ(132.631579deg);
}
.number:nth-child(15) {
  -webkit-transform: rotateZ(142.1052632deg);
          transform: rotateZ(142.1052632deg);
}
.number:nth-child(16) {
  -webkit-transform: rotateZ(151.5789474deg);
          transform: rotateZ(151.5789474deg);
}
.number:nth-child(17) {
  -webkit-transform: rotateZ(161.0526316deg);
          transform: rotateZ(161.0526316deg);
}
.number:nth-child(18) {
  -webkit-transform: rotateZ(170.5263158deg);
          transform: rotateZ(170.5263158deg);
}
.number:nth-child(19) {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}
.number:nth-child(20) {
  -webkit-transform: rotateZ(189.4736842deg);
          transform: rotateZ(189.4736842deg);
}
.number:nth-child(21) {
  -webkit-transform: rotateZ(198.9473684deg);
          transform: rotateZ(198.9473684deg);
}
.number:nth-child(22) {
  -webkit-transform: rotateZ(208.4210526deg);
          transform: rotateZ(208.4210526deg);
}
.number:nth-child(23) {
  -webkit-transform: rotateZ(217.8947368deg);
          transform: rotateZ(217.8947368deg);
}
.number:nth-child(24) {
  -webkit-transform: rotateZ(227.368421deg);
          transform: rotateZ(227.368421deg);
}
.number:nth-child(25) {
  -webkit-transform: rotateZ(236.8421052deg);
          transform: rotateZ(236.8421052deg);
}
.number:nth-child(26) {
  -webkit-transform: rotateZ(246.3157894deg);
          transform: rotateZ(246.3157894deg);
}
.number:nth-child(27) {
  -webkit-transform: rotateZ(255.7894736deg);
          transform: rotateZ(255.7894736deg);
}
.number:nth-child(28) {
  -webkit-transform: rotateZ(265.2631578deg);
          transform: rotateZ(265.2631578deg);
}
.number:nth-child(29) {
  -webkit-transform: rotateZ(274.7368419deg);
          transform: rotateZ(274.7368419deg);
}
.number:nth-child(30) {
  -webkit-transform: rotateZ(284.2105261deg);
          transform: rotateZ(284.2105261deg);
}
.number:nth-child(31) {
  -webkit-transform: rotateZ(293.68421029deg);
          transform: rotateZ(293.68421029deg);
}
.number:nth-child(32) {
  -webkit-transform: rotateZ(303.15789449deg);
          transform: rotateZ(303.15789449deg);
}
.number:nth-child(33) {
  -webkit-transform: rotateZ(312.6315786deg);
          transform: rotateZ(312.6315786deg);
}
.number:nth-child(34) {
  -webkit-transform: rotateZ(322.1052628deg);
          transform: rotateZ(322.1052628deg);
}
.number:nth-child(35) {
  -webkit-transform: rotateZ(331.57894699999997deg);
          transform: rotateZ(331.57894699999997deg);
}
.number:nth-child(36) {
  -webkit-transform: rotateZ(341.05263119deg);
          transform: rotateZ(341.05263119deg);
}
.number:nth-child(37) {
  -webkit-transform: rotateZ(350.52631539deg);
          transform: rotateZ(350.52631539deg);
}
.main {
  width: 350px;
  margin: 0px auto 0px;
  position: relative;
}
.plate {
  background-color: gray;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  position: relative;
  /* -webkit-animation: rotate 24s infinite linear;
  animation: rotate 24s infinite linear; */
}
/* .spin-fast {
  -webkit-animation: rotate 1s infinite linear;
  animation: rotate 1s infinite linear;
}

.spin-fast .data {
  animation: rotate 1s reverse linear infinite;
} */

.activate-spin {
  animation-name: rotatespin;
  animation-duration: 9s;
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  -webkit-transition: transform 9s ease-out;
  -webkit-animation-name: rotatespin;
  -webkit-animation-duration: 9s;
}

@-webkit-keyframes rotatespin {
  0% {
    left: 50%;
    transform: translateX(-50%) rotateZ(0deg);
    -webkit-transform: translateX(-50%) rotateZ(0deg);
  }
  100% {
    left: 50%;
    transform: translateX(-50%) rotateZ(1080deg);
    -webkit-transform: translateX(-50%) rotateZ(1080deg);
  }
}
@keyframes rotatespin {
  0% {
    left: 50%;
    transform: translateX(-50%) rotateZ(0deg);
    -webkit-transform: translateX(-50%) rotateZ(0deg);
  }
  100% {
    left: 50%;
    transform: translateX(-50%) rotateZ(1080deg);
    -webkit-transform: translateX(-50%) rotateZ(1080deg);
  }
}
.plate:after,
.plate:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
}
.plate:after {
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  border: 6px solid #000000;
  box-shadow: inset 0px 0px 0px 3px #e3e3e3, 0px 0px 0px 10px #000000;
}
.plate:before {
  background: rgba(0, 0, 0, 0.65);
  border: 1px solid #e3e3e3;
  box-shadow: inset 0px 0px 0px 2px #e3e3e3;
  top: 12%;
  left: 12%;
  right: 12%;
  bottom: 12%;
  z-index: 1;
}
.number {
  width: 32px;
  height: 175px;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 0;
  left: calc(50% - (32px / 2));
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  background-color: transparent;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 175px solid #04000f;
  box-sizing: border-box;
}
.number:nth-child(odd) {
  border-top-color: #fe2247;
}
.number:nth-child(38),
.number-new {
  border-top-color: #419e3f !important;
}
.pit {
  color: #fff;
  padding-top: 12px;
  width: 32px;
  display: inline-block;
  font-size: 13px;
  /* transform: scale(1, 1.8); */
  position: absolute;
  top: -175px;
  left: -16px;
  font-weight: 500;
}
.inner {
  display: block;
  height: 350px;
  width: 350px;
  position: relative;
  margin: 0;
}
.inner:after,
.inner:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
}
.inner:after {
  z-index: 3;
  top: 24%;
  right: 24%;
  bottom: 24%;
  left: 24%;
  background-color: #000000;
  border: 3px solid #e3e3e3;
}
.inner:before {
  top: 20%;
  bottom: 20%;
  left: 20%;
  right: 20%;
  content: "";
  color: #fff;
  font-size: 60px;
  z-index: 5;
  border-radius: 0;
}
.inner[data-spinto="1"]:before {
  -webkit-transform: rotateZ(-2596deg);
          transform: rotateZ(-2596deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="2"]:before {
  -webkit-transform: rotateZ(-2766deg);
          transform: rotateZ(-2766deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="3"]:before {
  -webkit-transform: rotateZ(-2842deg);
          transform: rotateZ(-2842deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="4"]:before {
  -webkit-transform: rotateZ(-2785deg);
          transform: rotateZ(-2785deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="5"]:before {
  -webkit-transform: rotateZ(-2633deg);
          transform: rotateZ(-2633deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="6"]:before {
  -webkit-transform: rotateZ(-2728deg);
          transform: rotateZ(-2728deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="7"]:before {
  -webkit-transform: rotateZ(-2520deg);
          transform: rotateZ(-2520deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="8"]:before {
  -webkit-transform: rotateZ(-2671deg);
          transform: rotateZ(-2671deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="9"]:before {
  -webkit-transform: rotateZ(-2558deg);
          transform: rotateZ(-2558deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="10"]:before {
  -webkit-transform: rotateZ(-2653deg);
          transform: rotateZ(-2653deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="11"]:before {
  -webkit-transform: rotateZ(-2690deg);
          transform: rotateZ(-2690deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="12"]:before {
  -webkit-transform: rotateZ(-2861deg);
          transform: rotateZ(-2861deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="13"]:before {
  -webkit-transform: rotateZ(-2710deg);
          transform: rotateZ(-2710deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="14"]:before {
  -webkit-transform: rotateZ(-2576deg);
          transform: rotateZ(-2576deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="15"]:before {
  -webkit-transform: rotateZ(-2804deg);
          transform: rotateZ(-2804deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="16"]:before {
  -webkit-transform: rotateZ(-2614deg);
          transform: rotateZ(-2614deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="17"]:before {
  -webkit-transform: rotateZ(-2747deg);
          transform: rotateZ(-2747deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="18"]:before {
  -webkit-transform: rotateZ(-2538deg);
          transform: rotateZ(-2538deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="19"]:before {
  -webkit-transform: rotateZ(-2794deg);
          transform: rotateZ(-2794deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="20"]:before {
  -webkit-transform: rotateZ(-2586deg);
          transform: rotateZ(-2586deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="21"]:before {
  -webkit-transform: rotateZ(-2776deg);
          transform: rotateZ(-2776deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="22"]:before {
  -webkit-transform: rotateZ(-2548deg);
          transform: rotateZ(-2548deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="23"]:before {
  -webkit-transform: rotateZ(-2662deg);
          transform: rotateZ(-2662deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="24"]:before {
  -webkit-transform: rotateZ(-2624deg);
          transform: rotateZ(-2624deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="25"]:before {
  -webkit-transform: rotateZ(-2756deg);
          transform: rotateZ(-2756deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="26"]:before {
  -webkit-transform: rotateZ(-2833deg);
          transform: rotateZ(-2833deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="27"]:before {
  -webkit-transform: rotateZ(-2718deg);
          transform: rotateZ(-2718deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="28"]:before {
  -webkit-transform: rotateZ(-2870deg);
          transform: rotateZ(-2870deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="29"]:before {
  -webkit-transform: rotateZ(-2528deg);
          transform: rotateZ(-2528deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="30"]:before {
  -webkit-transform: rotateZ(-2680deg);
          transform: rotateZ(-2680deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="31"]:before {
  -webkit-transform: rotateZ(-2567deg);
          transform: rotateZ(-2567deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="32"]:before {
  -webkit-transform: rotateZ(-2813deg);
          transform: rotateZ(-2813deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="33"]:before {
  -webkit-transform: rotateZ(-2604deg);
          transform: rotateZ(-2604deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="34"]:before {
  -webkit-transform: rotateZ(-2738deg);
          transform: rotateZ(-2738deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="35"]:before {
  -webkit-transform: rotateZ(-2491deg);
          transform: rotateZ(-2491deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="36"]:before {
  -webkit-transform: rotateZ(-2700deg);
          transform: rotateZ(-2700deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="0"]:before {
  -webkit-transform: rotateZ(-2822deg);
          transform: rotateZ(-2822deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner[data-spinto="00"]:before {
  -webkit-transform: rotateZ(-2643deg);
          transform: rotateZ(-2643deg);
  transition: -webkit-transform 9s ease-out;
  transition: transform 9s ease-out;
  transition: transform 9s ease-out, -webkit-transform 9s ease-out;
  content: "\2022";
}
.inner.rest:before {
  transition: top 0.5s ease-in, right 0.5s ease-in, bottom 0.5s ease-in,
    left 0.5s ease-in;
  top: 18%;
  right: 18%;
  bottom: 18%;
  left: 18%;
}

.btn .btn-label {
  padding: 12px;
  white-space: nowrap;
}
.btn.btn-reset {
  background: #21c9a6;
  border: 1px solid #21c9a6;
}
.btn.disabled {
  opacity: 0.2;
  transition: opacity 0.24s linear;
  cursor: not-allowed;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
}
.data {
  display: block;
  position: absolute;
  top: 30%;
  right: 30%;
  bottom: 30%;
  left: 30%;
  border-radius: 50%;
  /* animation: rotate 24s reverse linear infinite; */
  -webkit-perspective: 2000px;
          perspective: 2000px;
  z-index: 9;
}
.data .data-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.72s;
  transition: transform 0.72s;
  transition: transform 0.72s, -webkit-transform 0.72s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}
.data.reveal .data-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.data .mask,
.data .result {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}
.data .mask {
  color: #fff;
  font-size: 20px;
  margin: auto;
  line-height: 1.4;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data .result {
  background-color: #21c9a6;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  align-items: center;
  color: #fff;
}
.data .result-number {
  font-size: 72px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}
.data .result-color {
  text-transform: uppercase;
  font-size: 21px;
  line-height: 1;
}
.previous-results {
  max-width: 150px;
  position: fixed;
  text-transform: capitalize;
  right: 10px;
  top: 50px;
  width: 100%;
}
.previous-list {
  -webkit-perspective: 2000;
          perspective: 2000;
  margin: 0;
}
.previous-result {
  display: flex;
  margin: 1px;
  padding: 6px 12px;
  color: #fff;
}
.previous-result:first-child {
  -webkit-animation: flipin 0.48s ease-out;
  animation: flipin 0.48s ease-out;
}
.previous-result .previous-number {
  flex: 1 1;
}
.color-green {
  background-color: #21c9a6;
}
.color-black {
  background-color: black;
}
.color-red {
  background-color: #eb2958;
}
.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
@-webkit-keyframes flipin {
  0% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}
@keyframes flipin {
  0% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

.data-inner span {
  color: #fff;
  font-size: 25px;
}

/***************** Bet Table *******************/

.roulette-bet-table {
  width: 720px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  padding-top: 25px;
}

.roulette-bet-table table.bet-table {
  font-size: 24px;
}
.roulette-bet-table table {
  text-align: center;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  cursor: pointer;
}

.roulette-bet-table table tr {
  font-size: 16px;
  line-height: 0px;
}

.roulette-bet-table table.bet-table tr:first-child td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 2px solid #f8f5ff;
}
.roulette-bet-table table.bet-table tr:first-child td:nth-child(2),
.roulette-bet-table table.bet-table tr:first-child td:nth-child(3) {
  border-top: 2px solid white;
}
.roulette-bet-table table.bet-table tr:first-child td:nth-child(3) {
  border-right: 2px solid white;
}
.roulette-bet-table table.bet-table tr:first-child td {
  border-top: 0px solid #f8f5ff;
  border-bottom: 2px solid white;
}
.roulette-bet-table table.bet-table td {
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  /* border-right: 1px solid #f8f5ff;
  border-bottom: 1px solid #f8f5ff; */
  height: 50px;
  width: 50px;
  color: #fff;
  vertical-align: middle;
}
.red {
  background-color: #fe2247 !important;
}

.black {
  background-color: black !important;
}

.green {
  background-color: #419e3f !important;
}

.roulette-bet-table table.bet-table tr:first-child td:last-child {
  border-top-right-radius: 4px;
}

.roulette-bet-table table.bet-table tr td:first-child,
.roulette-bet-table table.bet-table tr td:last-child {
  width: 60px;
}

.roulette-bet-table table.bet-table td:last-child {
  font-size: 16px;
  background-color: #121b24;
}

.roulette-bet-table .grid-bet-table > div:first-child {
  border-radius: unset;
  border-top-left-radius: 4px;
}

/* .roulette-bet-table .grid-bet-table {
  border-radius: 0 0 4px 4px;
  border-width: 0 0 1px 1px;
  font-size: 16px;
  height: 100px;
  width: 618px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 102.8px);
  grid-template-rows: 1fr 1fr;
  background-color: #121b24;
  border: solid #f8f5ff;
  border: 1px solid #f8f5ff;
  margin-top: -1px;
  position: relative;
  left: -2px;
} */
.roulette-bet-table .grid-bet-table {
  border-radius: 0 0 4px 4px;
  border-width: 0 0 1px 1px;
  font-size: 16px;
  height: 100px;
  width: 570px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 94.8px);
  grid-template-rows: 1fr 1fr;
  /* background-color: #03071d; */
  background: linear-gradient(180deg, #001a47, #03051a);
  border: solid #f8f5ff;
  border: 1px solid #f8f5ff;
  margin-top: -1px;
  position: relative;
  left: 23px;
}

.roulette-bet-table .grid-bet-table > div.row-selection {
  grid-column-start: span 2;
  border-bottom: 1px solid #f8f5ff;
}

.roulette-bet-table .grid-bet-table > div {
  cursor: pointer;
  display: flex;
  color: #fff;
  border: solid #f8f5ff;
  border-width: 0 0 1px;
  align-items: center;
  justify-content: center;
  -webkit-text-orientation: mixed;
          text-orientation: mixed;
  border-left-width: 0;
  border-bottom-width: 0;
  border-right: 1px solid #f8f5ff;
  border-top-width: 0;
  -webkit-writing-mode: unset;
  writing-mode: unset;
  font-size: 16px;
  font-weight: 600;
}

.roulette-bet-table .grid-bet-table > div:nth-child(4) {
  border-bottom-left-radius: 4px;
}

.roulette-bet-table .grid-bet-table > div:not(:nth-child(-n + 3)) {
  margin-bottom: 0px;
}

.roulette-bet-table .grid-bet-table > div:last-child {
  border-bottom-left-radius: 4px;
  border-right: 0;
}

.roulette-bet-table .grid-bet-table > div.color-selection {
  width: unset;
  overflow: hidden;
}
.roulette-bet-table .grid-bet-table > div.color-selection svg {
  width: auto;
  -webkit-transform: unset;
  transform: unset;
}

.roulette-bet-table table.bet-table tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}

.roulette-bet-table .grid-bet-table > div:nth-child(3) {
  border-right: 0;
}
td.selected-area {
  width: 4px !important;
  height: 3px !important;
  background: #ffffff !important;
}
.active-class {
  background: #0d2b57 !important;
}

.hover-class {
  background: #0d2b57 !important;
}

/****** new css **************/
/* .roulette-table-section {
  background: #16212c;
  width: 64%;
  border-radius: 5px;
  padding: 20px 10px;
} */
.roulette-table-section .timer,
.roulette-table-section .username {
  font-size: 14px;
  color: #9d9d9d;
}
.roulette-bet-table table.bet-table tr:first-child td:first-child {
  border-left: 2px solid white !important;
  border-right: 0px solid #f8f5ff;
  border-bottom: 2px solid #f8f5ff;
  border-top: 2px solid white;
}
.roulette-bet-table table.bet-table tr td:last-child {
  border-right: 2px solid white;
}
.winner-section {
  width: 35%;
}
td.selected-area-zero {
  width: 4px !important;
  background: #ffffff;
}
.game-table-section {
  display: flex;
  justify-content: space-between;
}
.roulette-bet-table table.bet-table tr:last-child td:last-child {
  border-bottom: 1px solid white;
}
.winner-section .table-responsive {
  background: #16212c;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
}

.winner-section .table-responsive table {
  background: transparent;
  border: none;
}

.winner-section .table-responsive table tr td,
.winner-section .table-responsive table tr th {
  border: none;
  font-size: 12px;
  color: #7c8fa0;
}
.bottom-table .table-responsive {
  background: #03092280;
  padding: 0px;
}

.bottom-table .table-responsive table {
  background: transparent;
  border: none;
}

.bottom-table .table-responsive table tr td,
.bottom-table .table-responsive table tr th {
  border: none;
}

.bottom-table .table-responsive table thead {
  background: #121b24;
}

.bottom-table .table-responsive table thead th {
  font-size: 15px;
  font-weight: 600;
}
.wallet-amount img {
  width: 25px;
  margin-right: 10px;
}

.language {
  display: flex;
}

.wallet-amount {
  color: #ffffff;
  margin-right: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 13px;
}
.roulette-game-page {
  margin: 30px 0px 0px;
}
.bet-section {
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #001a47, #03051a);
  max-width: 60%;
  margin: 28px auto 0px;
  padding: 5px;
  border-radius: 5px;
}

.bet-section .bet-box {
  background: #121b24;
  margin: 5px;
  height: 35px;
  width: 35px;
  text-align: center;
  border-radius: 3px;
  font-size: 14px;
  line-height: 35px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-chips-section {
  /* background: #16212c; */
  background: linear-gradient(180deg, #001a47, #03051a);
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
}

.select-chips-section input {
  background: #121b24;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #121b24;
  width: 100%;
  font-size: 12px;
  min-height: 38px;
}

.chips-box-list button {
  margin: 0px !important;
  width: 25% !important;
  font-size: 14px !important;
}
.select-chips-section h6 {
  color: white;
}

.chips-box-list {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: flex-start;
}
.winner-section table tbody span {
  font-size: 12px;
  text-align: center;
  width: 100%;
}
.place-bet {
  margin-bottom: 10px;
}
.place-bet {
  position: relative;
}

.place-bet img {
  position: absolute;
  left: 5px;
  width: 22px;
  top: 9px;
}

.place-bet input {
  padding-left: 45px;
}
.bet-coins {
  display: flex;
  width: 60%;
  flex-wrap: wrap;
}
.chips-box-list .bet-box {
  padding: 4px;
  /* background: #121b24; */
  margin: 3px 1px;
  min-height: 35px;
  min-width: 40px;
  text-align: center;
  border-radius: 3px;
  font-size: 12px;
  line-height: 35px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
td.no-user {
  background: transparent !important;
}

tr.no-user {
  background: transparent !important;
}

tr.no-user td {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 0px !important;
}
.winner-section .table-responsive {
  height: 400px;
  overflow: auto;
}

.winner-section .table-responsive::-webkit-scrollbar {
  width: 5px;
}

.winner-section .table-responsive::-webkit-scrollbar-track {
  background: #181d22;
}

.winner-section .table-responsive::-webkit-scrollbar-thumb {
  background: #222c37;
}
.bet-box-disabled {
  cursor: not-allowed !important;
}

.roulette-game-page .winner {
  background-color: #21c9a6 !important;
}

.roulette-game-page .winner td {
  color: #fff !important;
}

.roulette-game-page .looser {
  background-color: #eb2958 !important;
}

.roulette-game-page .looser td {
  color: #fff !important;
}
li.number.red-number {
  border-top-color: #fe2247 !important;
}

li.number.black-number {
  border-top-color: #142134 !important;
}
.game-result pre {
  color: #fff;
  background: linear-gradient(180deg, #001a47, #03051a);
  padding: 20px;
  max-width: 800px;
  border-radius: 8px;
  margin-top: 30px;
}

.game-result {
  /* background: #001945; */
  background: linear-gradient(180deg, #001a47, #03051a);
  padding: 20px;
  max-width: 800px;
  border-radius: 8px;
  margin: 50px auto;
}

.game-result h2 {
  color: #fff;
  margin: 0;
  padding-bottom: 30px;
  text-align: center;
}

.game-result form .form-control {
  /* background: #001844; */
  background: #001844;
  border: 1px solid #071a46;
  color: #a9a9a9;
}
.game-result button {
  background: #f6ad75;
  border: 1px solid #f6ad75;
}
.verify-btn {
  display: flex;
  justify-content: center;
}
.bottom-table .table-responsive table tr td .copy-btn {
  width: 35px;
  height: 35px;
  background: #293746;
  border-radius: 6px;
  border-color: #293746;
  padding: 0px !important;
  margin-left: 5px !important;
}

button.rules-btn.btn.btn-primary {
  /* color: red; */
  margin-top: 0px;
  /* background-color: snow; */
  width: 58px;
}
.popup-img img {
  margin: 58px auto 0;
  width: 128px;
  height: 170px;
  display: block;
}
.welcome-img img {
  margin: 58px auto 0;
  width: auto;
  height: 170px;
  display: block;
}

.welcome-img h2 {
  margin: 0px auto;
  text-align: center;
  color: #12033d;
  font-weight: 800;
  padding-top: 40px;
}
.welcome-img {
  padding-top: 55px;
}
.welcome-btn .btn-primary {
  margin: 32px 146px 0;
  width: 182px;
  height: 50px;
  border-radius: 4px;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #0d42ff;
}
.popup-img h2 {
  margin: 48px auto 0;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 25px;
}
.popup-btn .btn-secondary {
  margin: 32px 17px 0;
  width: 182px;
  height: 50px;
  border-radius: 4px;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #ff2976;
}
.popup-btn .btn-primary {
  margin: 32px 17px 0;
  width: 182px;
  height: 50px;
  border-radius: 4px;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #0d42ff;
}
.header-top-login ul li span {
  display: block;
  padding: 12px 15px !important;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s;
  font-size: 14px;
  line-height: 14px;
  color: #7c8fa0;
}
.header-top-login ul li:last-child span {
  background: #f6ad75;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1b1f22;
}

/**********new css **************/
.roulette-game-page .roulette-table-section {
  width: 50%;
}
.roulette-game-page .winner-section {
  width: 80%;
  margin: 50px auto 0px ;
}
.roulette-game-page .game-table-section {
  align-items: flex-start;
}
.roulette-game-page .bottom-table .nav-tabs {
  border-bottom: 1px solid #80797933;
  background: #03092280;
}
.roulette-game-page .bottom-table .nav-tabs a {
  width: 12%;
  text-align: center;
  color: #7c8fa0;
  background: transparent;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding: 10px 35px;
}
.roulette-game-page .bottom-table .nav-tabs .nav-link:hover {
  border: 1px solid transparent;
  border-bottom: 1px solid #868686 !important;
}
.roulette-game-page .bottom-table .table-responsive table thead {
  background: transparent;
}
.roulette-game-page .bottom-table .nav-tabs .nav-link.active:hover {
  border-bottom: 2px solid #f6b077 !important;
}
.roulette-game-page .bottom-table .table-responsive table thead th {
  font-size: 13px;
  font-weight: 600;
}
.roulette-game-page .bottom-table .nav-tabs .nav-item.show .nav-link,
.roulette-game-page .bottom-table .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #eb2958;
  border-color: #eb2958 #eb2958 #eb2958;
}

.roulette-game-page .select-chips-section h6 {
  margin-bottom: 5px;
}
.roulette-game-page .select-chips-section button {
  width: auto !important;
  background: #f6ad75 !important;
  border: 1px solid #f6ad75 !important;
  color: white !important;
  text-transform: capitalize;
  padding: 0px 15px !important;
  height: 38px;
  font-weight: 500;
}
.roulette-game-page .select-chips-section {
  padding: 12px 20px !important;
  margin-bottom: 0px;
}
.roulette-game-page .select-chips-section .place-bet {
  margin-bottom: 20px;
}
.payouts-game span {
  color: #ffffff !important;
}
.roulette-game-page .bet-section {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.bottom-table table tr td {
  font-size: 13px;
  vertical-align: middle;
}
.bottom-table .table-responsive {
  padding-top: 0px;
}
.bottom-table table tr td a {
  color: #f6ad75;
}
.roulette-game-page .bottom-table .nav-tabs .nav-item.show .nav-link,
.roulette-game-page .bottom-table .nav-tabs .nav-link.active {
  color: #f6b077;
  background-color: transparent;
  border-color: transparent;
  border-bottom: 2px solid #f6b57e;
}

/********** rules popup css **************/
.rules-popup .nav-tabs .nav-link.active {
  background-color: #fe2247 !important;
  border: 1px solid #fe2247 !important;
}
.rules-popup .nav-tabs a {
  color: #fff !important;
  padding: 16px;
  border-radius: 4px;
  background-color: #f6ad75 !important;
  border: 1px solid #f6ad75 !important;
  flex-basis: 48%;
  text-align: center;
}
.rules-popup .nav-tabs {
  display: flex;
  justify-content: space-between;
  border-bottom: none !important;
}
.rules-popup .modal-content {
  height: 600px;
  margin-top: 0px;
  background: #121b24;
  overflow: hidden;
  background: linear-gradient(180deg, #001a47, #03051a);
}
.rules-popup .rules-tab {
  height: 420px;
  overflow-y: auto;
  padding-right: 10px;
  padding-bottom: 20px;
}
.rules-popup .profile-tab {
  height: 335px;
  overflow-y: auto;
  padding-right: 10px;
  padding-bottom: 20px;
}
.rules-popup .rules-tab::-webkit-scrollbar {
  width: 5px;
}
.profile-tabs.tab-pane h3 {
  color: white;
  font-size: 20px;
  margin-top: 13px;
  padding-top: 8px;
}
.profile-tabs.tab-pane h6 {
  color: white;
  font-size: 16px;
  margin-bottom: 13px;
  padding-top: 8px;
  font-weight: 400px;
  display: inline-block;
}
/************* rules popup *************/
.rules-popup .rules-tab::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.rules-popup .rules-tab::-webkit-scrollbar-thumb {
  background: #888;
}
.rules-popup .rules-tab * {
  color: white;
  font-size: 14px;
}
.rules-popup .rules-tab h4 b {
  font-size: 22px !important;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 500;
}
.rules-popup .modal-header {
  border-bottom: none;
  color: white;
}

.rules-popup button.close {
  color: white;
  display: none;
}

.rules-popup .card {
  border-radius: 4px;
  padding: 20px;
  background-color: #031a44;
  margin-bottom: 8px;
  color: white;
  margin-bottom: 20px;
}

.rules-popup .card .card-body {
  padding: 0px;
}

.rules-popup .card .card-body p {
  margin-bottom: 0px;
  font-weight: 300;
  font-size: 14px;
}

.rules-popup .close-icon {
  position: relative;
}

.rules-popup .close-icon img {
  width: 18px;
  position: absolute;
  right: 20px;
  top: -45px;
  cursor: pointer;
}

.rules-popup .card .card-body b {
  display: inline-block;
  padding-bottom: 12px;
}
/******Welcome and age popup *********/
.age-popup .popup-btn {
  /* position: absolute; */
  /* bottom: 35px; */
  /* width: calc(100% - 36px); */
  display: flex;
  justify-content: space-around;
  margin: 40px 0px;
}
.age-popup .popup-btn button {
  margin: 0px !important;
}
.popup-btn button:first-child {
  background: #fe2247 !important;
  border: 1px solid #fe2247;
}
.age-popup .modal-content,
.welcome-popup .modal-content {
  margin-top: 0px;
  background-color: #16212c !important;
  /* min-height: 65vh; */
}
.welcome-popup .welcome-img img {
  width: 125px;
  margin: 0px auto;
  height: auto;
}
.welcome-popup .modal-body {
  padding: 25px;
}
.welcome-img h2 {
  color: white;
  font-weight: 500;
  font-size: 25px;
}
.welcome-btn button {
  background: #f6ad75 !important;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1b1f22;
  border: 1px solid #f6ad75;
}
/* .welcome-popup .welcome-btn {
  position: absolute;
  bottom: 40px;
} */
.welcome-popup .modal-body {
  position: relative;
}

.winner-popup .modal-content {
  margin-top: 0px;
  background-color: #16212c !important;
  min-height: 20vh;
}
.winner-img h2 {
  color: white;
  font-weight: 500;
  font-size: 25px;
  text-align: center;
}

.payouts-game {
  color: #7c8fa0;
  font-size: 12px;
  max-width: 100%;
  background: #16212c;
  max-width: 200px;
  /* background: linear-gradient(180deg, #1f2f3c, #16212c); */
  background: linear-gradient(180deg, #001a47, #03051a);
  padding: 10px;
  width: 100%;
  border-radius: 5px;
}
/* .payouts-game {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
}
.payout-box {
  display: flex;
  justify-content: space-between;
  padding: 2px 0px;
  width: 20%
} */
.payout-box {
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
}
.payout-box span:last-child {
  color: #fff;
}
/* .payout-box span:last-child{
  font-weight: 600 !important;
  color: #eb2958 !important;
  font-size: 14px !important;
} */

.bottom-table .table-responsive table tr td img {
  height: 22px;
  width: 22px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}
.payout-section .bet h6 img {
  border-radius: 50%;
  margin-left: 5px;
}
.roulette-bet-table table.bet-table .red {
  position: relative;
  border: 1px solid #f1698a;
  background-image: linear-gradient(-180deg, #eb2958 0%, #eb2958 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(235 41 88) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(141 4 38) inset;
}

.roulette-bet-table table.bet-table .red::before {
  content: "";
  display: block;
  height: 0.25rem;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.roulette-bet-table table.bet-table .red::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  -webkit-filter: blur(1px);

          filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.roulette-bet-table table.bet-table .black {
  position: relative;
  border: 1px solid #000000;
  background-image: linear-gradient(-180deg, #000000 0%, #000000 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(255 255 255 / 0%),
    0 -0.25rem 1.5rem rgb(0 0 0) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(0 0 0) inset;
}

.roulette-bet-table table.bet-table .black::before {
  content: "";
  display: block;
  height: 0.25rem;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.roulette-bet-table table.bet-table .black::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  -webkit-filter: blur(1px);

          filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

/* .chips-box-list .bet-box {
  background-image: linear-gradient(-180deg, #121b24 0%, #121b24 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(18 27 36) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(18 27 36) inset;
} */
.bet-box.red {
  background-image: linear-gradient(-180deg, #eb2958 0%, #eb2958 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(235 41 88) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(141 4 38) inset;
}
.bet-box.black {
  background-image: linear-gradient(-180deg, #000000 0%, #000000 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(255 255 255 / 0%),
    0 -0.25rem 1.5rem rgb(0 0 0) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(0 0 0) inset;
}
.roulette-bet-table table.bet-table .green {
  position: relative;
  border: 1px solid #419d3f;
  background-image: linear-gradient(-180deg, #419e3f 0%, #419e3f 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(255 255 255 / 0%),
    0 -0.25rem 1.5rem rgb(61 148 59) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(156 254 156) inset;
}

.roulette-bet-table table.bet-table .green::before {
  content: "";
  display: block;
  height: 0.25rem;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.roulette-bet-table table.bet-table .green::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  -webkit-filter: blur(1px);

          filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.roulette-game-page .winner-section {
  width: 80%;
  margin-top: 50px;
  margin-left: auto;
  margin-right: 50px;
}
.roulette-wrapper {
  background: url(../../static/media/home-bg4.a714d611.png);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
}

.hover-class {
  background: #0d2b57 !important;
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(47 44 89) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 37%) inset,
    0 0.25rem 0.5rem 0 rgb(23 43 88) inset !important;
}
.active-class {
  background: #0d2b57 !important;
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(47 44 89) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 37%) inset,
    0 0.25rem 0.5rem 0 rgb(23 43 88) inset !important;
}
.bottom-table {
  padding-bottom: 50px;
}
.winner-user-section {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  z-index: 9;
  /* z-index: 999999; */
  background: linear-gradient(180deg, #1f2f3c, #16212c);
  padding: 15px;
  border-radius: 5px;
}

.winner-user-section h2 {
  font-size: 12px !important;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 0px;
}
.winner-user-section h2 p {
  font-size: 22px;
  font-weight: 700;
  margin-top: 10px;
}
.back-drop-shadow:before {
  content: "";
  height: 100%;
  width: 100%;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  z-index: 99;
  background: #00000080;
}
.roulette-wrapper-container {
  z-index: 99;
  /* position: relative; */
  padding-bottom: 50px;
}

.active-bet-box {
  border: 1px solid #f6ad75;
}
h4.bet-amount {
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
}
h6.bet-heading {
  color: #9893cb;
  line-height: 22px;
  font-size: 12px;
}

.user-bets-table {
  height: 200px;
  overflow-y: scroll;
}

.userWin {
  position: absolute;
  top: -18%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  z-index: 999999;
  background: #00143b;
  padding: 15px;
  /* border-radius: 5px; */
}

.payout-bet-section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.bet-revert-section {
  justify-content: space-between;
  max-width: 200px;
  align-items: center;
  margin-right: 18px;
  display: flex;
  border-radius: 5px;
  padding: 8px 20px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #dd2856;
}
/* .revert-bet {
  justify-content: space-between !important;
} */

.bet-revert-icon {
  border: 1px solid #fff;
  border-radius: 50%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  cursor: pointer;
  transition: all 0.5s;
}

.bet-revert-icon i {
  color: #fff;
  font-size: 16px;
  transition: all 0.5s;
}
.bet-revert-icon:hover {
  /* border-color: #eb2958; */
}

.bet-revert-section button {
  width: 300px;
  margin: 0 10px;
  background: #eb2958;
  border: 1px solid #eb2958;
  border-radius: 5px;
  transition: all 0.5s;
  box-shadow: 16px 41px 187px 9px rgba(255, 255, 255, 0.4);
}

.bet-revert-section button:disabled {
  background: #eb2958;
  border: 1px solid #eb2958;
}

.bet-revert-section button:hover {
  background: #1d2d39;
  border: 1px solid #243746;
}

.bet-total-container {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.bet-total-container-child {
  display: flex;
  /* width: 200px; */
  align-items: center;
  justify-content: space-between;
}
.bet-total-container-child h6, .bet-total-container-child h4{
  margin: 0px!important;
}
tr.pointer-cursor {
  cursor: pointer;
}
.modal {
  z-index: 999999 !important;
}
.user-pic {
  display: flex;
  align-items: center;
}

.user-pic img {
  margin-right: 10px;
}

.stastics {
  color: #7c8fa0;
  font-size: 12px;
  max-width: 239px;
  background: #16212c;
  width: 239px;
  background: linear-gradient(180deg, #001a47, #03051a);
  padding: 10px;
  border-radius: 5px;
}

.stat-flex {
  display: flex;
  justify-content: space-between;
}

.hot-cold-box {
  padding: 10px;
}

.hot-cold-box span {
  color: #fff;
}

.stat-bet-section {
  display: flex;
  justify-content: center;
  max-width: 50%;
  padding: 5px;
  padding-left: 24px;
  border-radius: 5px;
}

.stat-bet-section .bet-box {
  background: #121b24;
  margin: 5px;
  height: 25px;
  width: 25px;
  text-align: center;
  border-radius: 3px;
  font-size: 12px;
  line-height: 12px;
  padding: 6px;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

.stat-select {
  background-color: transparent;
  color: #aaa;
  outline: none;
  border: none;
}

.stat-meter {
  margin: 0 4px;
  height: 30px;
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}

.stat-meter-dark {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  padding: 5px;
  border: 1px solid #000000;
  background-image: linear-gradient(-180deg, #000000 0%, #000000 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(255 255 255 / 0%),
    0 -0.25rem 1.5rem rgb(0 0 0) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(0 0 0) inset;
}

.stat-meter-red {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  padding: 5px;
  border: 1px solid #f1698a;
  background-image: linear-gradient(-180deg, #eb2958 0%, #eb2958 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(235 41 88) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(141 4 38) inset;
}

.stat-meter-green {
  padding: 5px 0px;
  color: #fff;
  border: 1px solid #419d3f;
  background-image: linear-gradient(-180deg, #419e3f 0%, #419e3f 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(255 255 255 / 0%),
    0 -0.25rem 1.5rem rgb(61 148 59) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(156 254 156) inset;
}

.stat-meter-odd {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  padding: 5px;
  border: 1px solid #1a0e11;
  background-image: linear-gradient(-180deg, #1b1013 0%, #0e090a 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
    0 -0.25rem 1.5rem rgb(27, 15, 18) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(12, 5, 7) inset;
}

.main-game-list .mobile-view-button {
  display: none;
}

.small-bet-section {
  display: none;
}
img.btc-img {
  height: 20px;
  width: 20px;
  margin-left: 5px;
  border-radius: 50%;
}
/********** statistic section css ***************/
.statistic-section {
  width: 100%;
  margin-top: 25px;
}

.statistic-section .stastics {
  width: 100% !important;
  max-width: 100% !important;
  margin-bottom: 0px;
  padding: 0px;
}
.left-statistic-section {
  width: 40%;
}

.right-statistic-section {
  width: 55%;
}

.stastics {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.left-statistic-section .stat-flex.hot-cold-box {
  padding: 0px !important;
}
.left-hot-number {
  width: 50%;
}

.right-hot-number {
  width: 50%;
}

.left-hot-number .stat-bet-section {
  justify-content: flex-start;
  width: 100%;
  max-width: 100% !important;
  padding-left: 0px !important;
}

.right-hot-number .stat-bet-section {
  max-width: 100%;
  justify-content: flex-end;
  padding: 5px 0px;
}
.dozens-list {
  display: flex;
  justify-content: space-between;
}
.dozens-list h6 {
  font-size: 12px;
  color: white;
}
.statistics-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statistics-options h6 {
  color: white;
}
.statistic-section {
  background: linear-gradient(180deg, #001a47, #03051a);
  padding: 20px;
}
.right-statistic-section h6,
.right-statistic-section p {
  margin-bottom: 5px;
}
.left-statistic-section h6,
.left-statistic-section p {
  margin-bottom: 5px;
}
.statistic-section .stastics {
  background: transparent !important;
  margin-top: 15px;
}
.statistic-section .stat-select {
  font-size: 12px;
  cursor: pointer;
}
.left-statistic-section h4,
.right-statistic-section h4 {
  color: white;
  font-weight: 600;
  font-size: 12px;
}
.stat-bet-section .bet-box:first-child {
  margin-left: 0px !important;
}
.right-hot-number .bet-box:last-child {
  margin-right: 0px !important;
}
.header.sticky {
  background: linear-gradient(180deg, #001a47, #03051a);
}
/**********chips css ************/
/* td.selected-area-zero
.chips-section svg {
  position: absolute;
  height: 30px;
  width: 30px;
  transform: translateX(-50%);
  top: 0px;
  border-radius: 50%;
} */
.chips-section {
  position: absolute;
  background: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin: 0px auto;
  border-radius: 50%;
}
/* .grid-bet-table .chips-section svg {
  position: absolute;
  height: 30px;
  width: 30px;
  transform: translateX(-50%);
  top: 0;
  left: 15px;
} */
/* .grid-bet-table .color-selection .chips-section svg {
  position: absolute;
  height: 30px;
  width: 30px !important;
  transform: translate(50%, 50%) !important;
  top: -15px;
  left: -15px;
} */
.bet-table tr td .chips-section {
  position: absolute;
  background: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin: 0px auto;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  z-index: 9;
  top: 50%;
  border-radius: 50%;
}
.chips-section {
  border-radius: 50%;
}
.chips-section svg {
  border-radius: 50%;
}
td.selected-area-zero,
.selected-area {
  position: relative;
}
.pagination button,
.pagination button:hover,
.pagination button:focus {
  background: #212e3b;
  border-radius: 3px;
  border-color: #212e3b;
  min-width: 143px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #7c8fa0;
  padding: 11px 15px;
  text-align: left;
  position: relative;
  box-shadow: none;
}
.pagination button:last-child img {
  margin-left: 10px;
  position: absolute;
  right: 18px;
  top: 14px;
}

.pagination button:first-child img {
  margin-right: 10px;
  position: absolute;
  left: 18px;
  top: 14px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.pagination button:first-child {
  text-align: right;
}

.pagination button.disabled {
  opacity: 0.5;
  background: #212e3b;
  border-color: #212e3b;
  pointer-events: none;
  color: #7c8fa0;
}
.pagination-chk {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.pagination ul li {
  margin: 5px 10px;
  color: #f6ad75;
  font-size: 14px !important;
}

.pagination .pagination__link--disabled {
  background: transparent !important;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 14px !important;
  cursor: not-allowed;
  border: 1px solid #f6ad75;
  opacity: 0.8;
}

.pagination .pagination__link--disabled a {
  color: white !important;
  font-weight: 400;
}

.pagination li.next,
.pagination li.previous {
  background: #f6ad75;
  padding: 8px 25px;
  border-radius: 5px;
  min-width: 80px;
  text-align: center;
}

.pagination li.next a,
.pagination li.previous a {
  color: black;
  font-weight: 400;
}

.pagination li.pagination__link--active {
  color: white !important;
}
.pagination a {
  text-decoration: none !important;
}
.pagination li.next.pagination__link--disabled,
li.previous.pagination__link--disabled {
  display: none;
}

.crypto-select {
  width: 200px;
}

.custom-select-option {
  position: relative;
}

.custom-select-option h6 {
  background: transparent;
  padding: 5px 0px;
  border-radius: 5px;
  min-height: 35px;
  display: flex;
  align-items: center;
  /* border: 1px solid #0c2044; */
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
}
.select-balance {
  display: flex;
  margin-right: 5px;
}
.select-option {
  background: #001a46;
  padding: 5px;
  border-radius: 5px;
  max-height: 140px;
  overflow: auto;
  position: absolute;
  max-width: 100%;
  width: 100%;
  top: 37px;
}

.select-option p {
  margin-bottom: 0px;
  padding: 10px 5px;
  color: white;
  font-size: 14px;
  border-bottom: 1px solid #091b58;
  cursor: pointer;
}

.select-option::-webkit-scrollbar {
  width: 5px;
}

.select-option::-webkit-scrollbar-track {
  background: #181d22;
}

.select-option::-webkit-scrollbar-thumb {
  background: #222c37;
}
.select-option p img {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  border-radius: 50%;
  object-fit: cover;
}
.custom-select-option img.icon-img {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  border-radius: 50%;
  object-fit: cover;
}

.custom-select-option h6 img {
  height: 20px;
  cursor: pointer;
}

.custom-select-option h6 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

/* .landing-featured-list-content ul li {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.chips-section .chips-box {
  position: relative;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

.chips-section .chips-box > * {
  display: block;
  margin-top: -29px;
}

.chips-section .chips-box > *:first-child {
  margin-top: 0px;
}

.chips-box {
  position: relative;
}
.bet-total-container .fa-info-circle {
  margin-left: 10px;
  cursor: pointer;
}
.wallet-input a img {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  width: 25px;
  object-fit: cover;
}
.wallet-input a.dropdown-item {
  padding: 10px;
  color: white;
}
.wallet-input a.dropdown-item:hover {
  background-color: transparent !important;
}
.wallet-input button:focus {
  outline: none;
  box-shadow: none;
}
.limit-sec h6 {
  color: #fff;
  font-size: 12px;
}
.limit-sec .fa-info-circle {
  margin-right: 10px;
  cursor: pointer;
}
.limit-sec {
  padding: 0px 4px;
}
.add-sidebar body {
  overflow: hidden;
}

.add-sidebar .small-bet-section.open-bet-section {
  top: 0px;
  z-index: 9999999 !important;
}
/* .place-bet-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.place-bet-heading .bet-coins {
  width: 100%;
}
.revert-bet-section {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  width: 100%;
  /* background: #fe2247; */
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  margin-top: 25px;
}
.revert-bet-section img {
  height: 21px;
  cursor: pointer;
}

.revert-bet-section span {
  border: none !important;
}
/* .roulette-table-section .bet-section {
  display: none;
} */
.statistic-section {
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
}
.welcome-popup .modal-content {
  border: none;
}

.revert-bet-section {
  display: flex;
  max-width: 213px;
  justify-content: space-between;
  width: 100%;
  background: #3d3d3d;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  margin-top: 0;
  position: absolute;
  top: 0px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  border: 1px solid #eb2958;
  background-image: linear-gradient(-180deg, #ea4d74 0%, #e32f5a 100%);
  box-shadow: 0 1rem 1.25rem 0 rgb(255 255 255 / 0%),
    0 -0.25rem 1.5rem rgb(234 42 88) inset,
    0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem 0.5rem 0 rgb(236 79 117) inset;
}
.select-chips-section {
  position: relative;
}

.rolulette-right-table-section::-webkit-scrollbar {
  height: 5px;
}

.rolulette-right-table-section::-webkit-scrollbar-track {
  background: #030921;
}

.rolulette-right-table-section::-webkit-scrollbar-thumb {
  background: #001a47;
}
.welcome-popup .welcome-btn {
  padding-bottom: 30px;
}
.bet-coins {
  padding-bottom: 15px;
}
.bet-total-container-child {
  padding: 5px 0px;
}
.bet-box img {
  height: 35px !important;
  width: 35px !important;
}

.hidden-body {
  overflow: hidden;
}
.md-ddnone.classname {
  padding-top: 75px;
}

.userWin {
  position: fixed;
  top: -18%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  z-index: 999999;
  background: #071b43;
  padding: 15px;
  /* border-radius: 5px; */
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  top: 50%;
  display: flex;
  justify-content: center;
  border-top: 1px solid #40250c;
  align-items: center;
  max-width: 400px;
  border-bottom: 1px solid #3d240c;
  background: linear-gradient(to right, #000000db, #2a321e);
}
.overlay {
  position: fixed;
  top: -18%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  z-index: 999999;
  background: #64646480;
  padding: 15px;
  /* border-radius: 5px; */
  width: 100%;
  height: 100%;
  top: 50%;
  display: flex;
  justify-content: center;
}
.userWin h2 {
  color: rgb(6, 175, 14);
  font-size: 35px;
}
.user-loser h2 {
  color: red;
  font-size: 35px;
}
.user-loser {
  background: linear-gradient(to right, #071b43db, #432607);
}
.winner-img {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.winner-img img {
  height: 300px;
}

/******* leave confirmation popup *********/
.leave-confirm .modal-content {
  background: #1b1f2e;
  color: #fff;
}

.leave-confirm .modal-header {
  border: none;
}

.leave-confirm .modal-content .block {
  text-align: center;
}

.leave-confirm .modal-content .block p {
  font-size: 22px;
  max-width: 350px;
  margin: 0px auto;
  padding-bottom: 40px;
}

.block-view h4 {
  margin: 0;
  padding-bottom: 20px;
  font-size: 22px;
}

.leave-confirm .modal-content .modal-body {
  padding: 60px 30px 60px;
}

.leave-confirm button.btn.btn-primary:first-child {
  padding: 8px 20px !important;
  font-size: 15px;
  background: #f6ad75;
  border-radius: 3px;
  border: 1px solid #f6ad75;
  color: black;
}

.leave-confirm button.btn.btn-primary:last-child {
  padding: 8px 20px !important;
  font-size: 15px;
  background: transparent;
  border-radius: 3px;
  border: 1px solid #f6ad75;
  color: #f6ad75 !important;
}
.bottom-table table tr td button, .bottom-table table tr td button:hover, .bottom-table table tr td button:focus, 
.bottom-table table tr td .btn-warning:not(:disabled):not(.disabled):active
{
  padding: 8px 20px !important;
  font-size: 15px;
  background: transparent;
  border-radius: 5px;
  border: 1px solid #f6ad75;
  color: #f6ad75 !important;
  box-shadow: none;
  outline: none;
}
.leave-confirm .modal-content {
  border: none !important;
  background: linear-gradient(180deg, #001a47, #03051a) !important;
}
.leave-confirm .sub-btn button {
  margin: 0 10px;
}

/******* animation *********/

.winning-animation-win > .before,
.winning-animation-win > .after {
  z-index: 9999999999;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff;
  -webkit-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.winning-animation-win > .after {
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: 183px 79.3333333333px #ffe100, -36px 15.3333333333px #15ff00,
      13px -55.6666666667px #00ff59, -29px -405.6666666667px #ff008c,
      219px -44.6666666667px #ff6f00, -160px -190.6666666667px #00ff4d,
      -67px -273.6666666667px #00ff6f, -22px -215.6666666667px #9500ff,
      205px -28.6666666667px #fbff00, 37px 55.3333333333px #00fbff,
      37px -232.6666666667px #ff004d, 3px -66.6666666667px #006aff,
      115px -342.6666666667px #0073ff, -106px 2.3333333333px #2200ff,
      -3px -411.6666666667px #00d9ff, -217px -223.6666666667px darkorange,
      107px -200.6666666667px #00ff51, 88px -387.6666666667px #ff0009,
      196px 24.3333333333px #0400ff, 211px -314.6666666667px #5eff00,
      -46px -289.6666666667px #00ff26, 69px -404.6666666667px #fbff00,
      -30px -326.6666666667px #ffaa00, 69px 62.3333333333px #00ffe1,
      -194px -360.6666666667px #ffa600, 154px -372.6666666667px #003cff,
      -182px 43.3333333333px #ff3300, -220px -406.6666666667px #a200ff,
      -67px -81.6666666667px red, -168px 43.3333333333px #ff4800,
      -75px -102.6666666667px #00aeff, 180px -89.6666666667px #002fff,
      52px 53.3333333333px #4dff00, 125px -211.6666666667px #a600ff,
      -48px -80.6666666667px #37ff00, -242px -313.6666666667px #0044ff,
      -81px -115.6666666667px #00ff8c, 224px -196.6666666667px #d5ff00,
      89px -102.6666666667px #00ff11, -59px -247.6666666667px #00d5ff,
      -90px -101.6666666667px #0088ff, 151px -223.6666666667px #66ff00,
      113px -45.6666666667px #00ffbb, 79px -409.6666666667px #2bff00,
      48px -123.6666666667px #a200ff, -209px 82.3333333333px #e1ff00,
      134px -0.6666666667px #00ffc8, 73px -139.6666666667px #00aaff,
      -126px -87.6666666667px #6200ff, 236px -237.6666666667px #ff6a00,
      -230px -246.6666666667px #ff4400;
  }
}
@keyframes bang {
  to {
    box-shadow: 183px 79.3333333333px #ffe100, -36px 15.3333333333px #15ff00,
      13px -55.6666666667px #00ff59, -29px -405.6666666667px #ff008c,
      219px -44.6666666667px #ff6f00, -160px -190.6666666667px #00ff4d,
      -67px -273.6666666667px #00ff6f, -22px -215.6666666667px #9500ff,
      205px -28.6666666667px #fbff00, 37px 55.3333333333px #00fbff,
      37px -232.6666666667px #ff004d, 3px -66.6666666667px #006aff,
      115px -342.6666666667px #0073ff, -106px 2.3333333333px #2200ff,
      -3px -411.6666666667px #00d9ff, -217px -223.6666666667px darkorange,
      107px -200.6666666667px #00ff51, 88px -387.6666666667px #ff0009,
      196px 24.3333333333px #0400ff, 211px -314.6666666667px #5eff00,
      -46px -289.6666666667px #00ff26, 69px -404.6666666667px #fbff00,
      -30px -326.6666666667px #ffaa00, 69px 62.3333333333px #00ffe1,
      -194px -360.6666666667px #ffa600, 154px -372.6666666667px #003cff,
      -182px 43.3333333333px #ff3300, -220px -406.6666666667px #a200ff,
      -67px -81.6666666667px red, -168px 43.3333333333px #ff4800,
      -75px -102.6666666667px #00aeff, 180px -89.6666666667px #002fff,
      52px 53.3333333333px #4dff00, 125px -211.6666666667px #a600ff,
      -48px -80.6666666667px #37ff00, -242px -313.6666666667px #0044ff,
      -81px -115.6666666667px #00ff8c, 224px -196.6666666667px #d5ff00,
      89px -102.6666666667px #00ff11, -59px -247.6666666667px #00d5ff,
      -90px -101.6666666667px #0088ff, 151px -223.6666666667px #66ff00,
      113px -45.6666666667px #00ffbb, 79px -409.6666666667px #2bff00,
      48px -123.6666666667px #a200ff, -209px 82.3333333333px #e1ff00,
      134px -0.6666666667px #00ffc8, 73px -139.6666666667px #00aaff,
      -126px -87.6666666667px #6200ff, 236px -237.6666666667px #ff6a00,
      -230px -246.6666666667px #ff4400;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

.winning-animation {
  position: fixed;
  z-index: 999999;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
}
.winning-animation:before {
  content: "";
  position: absolute;
  background: #000000cc;
  height: 100%;
  width: 100%;
  z-index: 999999;
}
.winning-animation .winning-amount {
  color: white;
  z-index: 9999999999;
  position: absolute;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  top: 50%;
  left: 50%;
  font-size: 40px;
  color: #f7f7f7;
  padding: 41px 20px;
  max-width: 600px;
  width: 100%;
  text-align: center;
  background-image: linear-gradient(to right, #eb295800, #eb2958, #eb295800);
  -webkit-animation: neon1 0.5s ease-in-out;
          animation: neon1 0.5s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes neon1 {
  from {
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
  }
  to {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes neon1 {
  from {
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
  }
  to {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}
.winning-animation .winning-amount h2 {
  color: white;
  font-size: 48px;
  text-align: center;
  font-family: 'Righteous', cursive !important;
  margin-bottom: 10px;
}

.winning-amount p {
  font-size: 16px;
  margin: 0;
  color: white;
  font-weight: 400;
}
.close-img img {
  position: absolute;
  z-index: 9999999;
  height: 22px;
  right: 16px;
  top: 15px;
  cursor: pointer;
}
.stat-meter-red,
.stat-meter-green,
.stat-meter-dark,
.stat-meter-odd {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.winning-animation-win .winning-amount {
  background-image: linear-gradient(to right, #337a1700, #337a17, #337a1700);
}

.winns img {
  width: 40px;
  border-radius: 100%;
  margin-right: 10px;
  height: 40px;
}
.flex-win {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: 60%;
  margin: 5px auto;
  font-size: 15px;
}

.flex-win img {
  height: 18px;
  width: 18px;
  margin-left: 5px;
}
.flex-win span {
  width: 40%;
  display: flex;
  align-items: center;
}

/***** slot result *********/
.left-slot-result {
  width: 40%;
}

.right-slot-result {
  width: 60%;
}

.right-slot-result img {
  height: 50px !important;
  width: 100% !important;
  object-fit: contain;
}

.slot-result-section {
  display: flex;
  margin: 20px 0px;
}

.slot-images {
  display: flex;
  margin-bottom: 10px;
}

.slot-numbers {
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.winner-section.last-result{
  margin-top: 10px !important;
}
.winner-section.last-result p {
  color: white;
  font-size: 14px;
  font-weight: 400;
}
.winner-section.last-result .result-listing {
  display: flex;
  /* border-top: 1px dashed #434343; */
  padding: 3px 0px;
  margin: 5px 0px;
  width: 40%;
  align-items: center;
}

.winner-section.last-result .result-listing p {
  width: 50%;
  margin: 0;
  font-size: 12px;
}

.winner-section.last-result .result-listing span {
  color: #b9b9b9;
  width: 50%;
  text-align: right;
  font-size: 12px;
}
.result-listiing-section{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  height: 66px;
  background: transparent;
  /* background: linear-gradient(180deg, #1f2f3c, #16212c); */
}
.header.sticky {
  z-index: 999;
}

.main-wrapper {
  overflow-x: hidden;
}

.header-logo {
  max-width: 120px;
  width: 100%;
}

.header-logo img {
  width: 80px;
  position: absolute;
  top: -25px;
  left: 0;
  cursor: pointer;
}

.header-logo .main-logo-border {
  -webkit-animation: spin 8s linear infinite;
  animation: spin 8s linear infinite;
}
.add-sidebar .header.sticky{
  z-index: -1;
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* .header-logo img {
  width: 100px;
  position: absolute;
  top: -34px;
} */

.mobile-login,
.header-logo-mobile {
  display: none;
}

.header-logo h2 {
  color: #f6ad75;
  font-weight: 700;
  font-size: 36px;
  letter-spacing: 0.5px;
  margin: 0;
}

.header-logo a:hover {
  text-decoration: none;
}

.header-top-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 14px 0px;
}

.main-game-list {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0px 0px;
}

.header-logo {
  padding: 0px 15px;
}

.header-top-area .navbar-expand .navbar-nav .nav-link {
  padding: 0px 30px;
  font-weight: 600;
  font-size: 16px;
  position: relative;
  color: #fff;
  display: block;
}

.header-top-area .navbar-expand-lg .navbar-nav .nav-link:first-child {
  padding-left: 6px;
}

.header-top-area .navbar-light .navbar-nav .nav-link {
  color: #7c8fa0;
}

.header-top-area .navbar-light .navbar-nav .nav-link.active span::after {
  width: 100%;
  height: 2px;
  content: "";
  display: block;
  position: absolute;
  background: #f6ad75;
  left: 0;
  bottom: -24px;
}

.header-top-area .navbar-expand-lg .navbar-nav .nav-link {
  padding: 0 30px;
}

.header-top-area .navbar-expand-lg .navbar-nav .nav-link span {
  position: relative;
  font-size: 16px;
  line-height: 14px;
  font-family: "Averta-Semibold";
}

.header-top-area nav.navbar.navbar-expand.navbar-light {
  padding: 0;
}

.header-top-area .navbar-light .navbar-nav .nav-link.active {
  color: #f6ad75;
}

.header-top-area .navbar-light .navbar-nav .nav-link.active:hover {
  color: #f6ad75;
}

.header-top-area .navbar-expand-lg .navbar-nav .nav-link span img {
  margin-right: 5px;
}

.navbar-expand-lg {
  padding: 0 !important;
}

.header-top-login ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-top-login ul li span {
  display: block;
  padding: 12px 20px;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s;
  font-size: 14px;
  line-height: 14px;
  color: #7c8fa0;
  font-weight: 600;
}

.header-top-login ul li span a {
  color: #fff;
}

.header-top-login ul li span a:hover,
.header-top-login ul li span a:focus {
  color: #fff;
  text-decoration: none;
}

.header-top-login ul li:last-child span {
  background: #f6ad75;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #1b1f22;
}

.header-top-login ul li:last-child span:hover {
  background: #f6ad75;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #1b1f22;
}

.header-top-login ul li span i {
  margin-right: 5px;
}

.header-top-area .navbar-light .navbar-nav .nav-link:focus,
.header-top-area .navbar-light .navbar-nav .nav-link:hover {
  color: #7c8fa0;
}

.header-top-login ul li.or {
  margin: 0 10px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

ul {
  margin: 0 !important;
  list-style-type: none !important;
  padding: 0 !important;
}

.header-top-login {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  justify-content: flex-end;
}

.header-top-login > a {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #7c8fa0;
  margin-left: 20px;
}

.header-top-login > a:hover {
  text-decoration: none;
  color: #7c8fa0;
}

.header-top-login.mobile-login {
  display: none;
}

.my-profile-menu {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.p-pic img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 5px;
}

.user-pic img {
  width: 22px;
  height: 22px;
  object-fit: cover;
  border-radius: 15px;
}

.p-name h5 {
  font-size: 14px;
  margin: 0;
  color: #fff;
}

.p-name {
  padding-left: 10px;
}

.my-profile-menu .dropdown .btn,
.my-profile-menu .dropdown .btn:hover,
.my-profile-menu .dropdown .btn:focus {
  background: transparent !important;
  color: #fff;
  border-color: transparent;
  border: none;
  padding: 0;
  font-size: 14px;
  box-shadow: none;
  outline: none;
}

.my-profile-menu .dropdown-item {
  font-size: 13px;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none !important;
}

.p-name .dropdown-menu.show {
  display: block;
  right: 0;
  left: inherit;
  border: 1px solid #001a47;
  margin-top: 10px !important;
  /* background: #16212c; */
  background: linear-gradient( 180deg ,#001a47,#03051a);
  border-radius: 2px;
  padding: 6px;
}

.p-name .dropdown-menu a {
  display: block;
  width: 100%;
  clear: both;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  padding: 8px 15px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7c8fa0;
}

.p-name .dropdown-menu a:hover {
  text-decoration: none;
  background: #212e3b;
  border-radius: 2px;
  color: #7c8fa0;
}

.loggedin-menu {
  display: flex;
  align-items: center;
}

.loggedin-menu .credit-input form {
  display: flex;
  max-width: 180px;
  position: relative;
}
.loggedin-menu .credit-input .form-control,
.loggedin-menu .credit-input .form-control:focus {
  height: 35px;
  padding-left: 30px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  box-shadow: none;
  background: rgba(14, 22, 30, 0.55);
  border: 1px solid #192633;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100px;
  pointer-events: none;
}

.loggedin-menu .credit-input .form-control::-webkit-outer-spin-button,
.loggedin-menu .credit-input .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loggedin-menu .credit-input .form-control {
  -moz-appearance: textfield;
}

.loggedin-menu a {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #7c8fa0;
}

.loggedin-menu a:hover {
  text-decoration: none;
  color: #f6ad75;
}

.loggedin-menu .credit-input {
  margin-left: 20px;
}

.loggedin-menu .credit-input form .btn,
.loggedin-menu .credit-input form .btn:hover,
.loggedin-menu .credit-input form .btn:focus {
  height: 35px;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #1b1f22;
  background: #f6ad75;
  border-radius: 3px;
  border-color: #f6ad75;
  box-shadow: none;
}

.loggedin-menu .btn-primary:not(:disabled):not(.disabled):active,
.loggedin-menu .btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #f6ad75;
  border-radius: 3px;
  border-color: #f6ad75;
  box-shadow: none;
}

.loggedin-menu .credit-input .form-control::-webkit-input-placeholder {
  color: #fff;
}

.loggedin-menu .credit-input .form-control::placeholder {
  color: #fff;
}

.loggedin-menu .credit-input form img {
  width: 10px;
  position: absolute;
  z-index: 9;
  left: 11px;
  top: 17px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.p-name .dropdown-menu a img {
  margin-right: 5px;
}
.mob-logo {
  display: none;
}
.language select,
.language select:focus {
  background: transparent;
  border: none;
  color: #f6ad75;
  margin-left: 15px;
  outline: none;
  box-shadow: none;
}
.language select option {
  color: black;
  background: transparent;
  padding: 0px 10px;
}

.header-top-area button#dropdown-profile {
  color: #ffffff !important;
  background-color: transparent;
  border-color: transparent;
}
.content-wrapper .form-input input.form-control {
  padding-left: 10px;
}
.content-wrapper .settings-content .form-label {
  margin-left: 3px;
}
.content-wrapper button.save-btn.btn.btn-primary {
  color: rgb(2, 2, 2);
  background-color: #eda773;
  border-color: #eda773;
  font-weight: 400;
}
.header-top-area .dropdown-menu.show {
  min-width: 50px !important;
  font-size: 14px;
  background: #00153d;
  padding: 4px;
  /* width: 10rem !important; */
}
.header-top-area .dropdown-menu a:hover {
  background: #121b24;
}
.header-top-area .dropdown-item {
  color: rgb(0, 0, 0);
}
.wallet-amount img {
  width: 25px;
  margin-right: 10px;
}
.header-top-login .wallet-amount {
  color: #fff;
  margin-right: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.volume-btn button {
  background: #121b24;
  border: 1px solid #121b24;
  color: #fff !important;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  transition: all 0.5s;
}

.volume-btn button:hover {
  background: #1d2d39;
  border: 1px solid #243746;
}

.rules-box {
  margin-left: 20px;
}

.rules-box span {
  background: #eb2958;
  border: 1px solid #eb2958;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  padding: 4px 10px;
  border-radius: 3px;
  min-width: 80px;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
}

.rules-box span:hover {
  background: #1d2d39;
  border: 1px solid #243746;
}

.wallet-input {
  /* background: #121b24; */
  /* border: 1px solid #121b24; */
  display: flex;
  padding: 5px 10px;
  /* border-radius: 3px; */
  color: #fff;
  font-size: 14px;
  transition: all 0.5s;
  cursor: pointer;
  align-items: center;
}

.wallet-input {
  margin-left: 20px;
}

.wallet-input img {
  margin-right: 8px;
  width: 25px;
}
button.exit-game.btn.btn-primary{
  background: #f6ad75!important;
  font-weight: 600;
  height: 38px;
  border: #f6ad75;
  font-size: 14px;
  line-height: 14px;
  border-radius: 5px;
  color: #1b1f22!important;
  outline: none!important;
  transition: all 5s;
  box-shadow: none!important;
  padding: 12px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}
button.exit-game.btn.btn-primary i{
  margin-right: 8px;
}
/* .wallet-input:hover {
  background: #1d2d39;
  border: 1px solid #243746;
} */

/*************** Media Query ****************/

@media screen and (max-width: 991px) {
  .profile-menu {
    min-height: auto;
  }

  .header-top-area {
    min-height: 62px;
  }

  .header-logo {
    width: 100px;
  }

  .header-logo img {
    width: 65px;
  }
  .navbar-light .navbar-toggler-icon {
    display: none !important;
  }

  .header-top-area .navbar-light .navbar-toggler.collapsed {
    padding: 0;
    border: none;
    color: transparent;
    position: relative;
    width: 26px;
    height: 26px;
    border-top: 2px solid #ffffff;
    border-radius: 0;
    top: 6px;
  }

  .header-top-area .navbar-light .navbar-toggler {
    padding: 0;
    border: none;
    color: transparent;
    position: relative;
    width: 26px;
    height: 26px;
    border-top: 2px solid transparent;
    border-radius: 0;
    top: 2px;
  }
  .header-top-login {
    display: none;
  }

  .header-top-area .navbar-light .navbar-toggler.collapsed::before {
    content: "";
    background: #ffffff;
    width: 20px;
    height: 2px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 6px;
    left: 0;
    right: 0;
    transition: 0.4s all ease;
    -webkit-transform: inherit;
            transform: inherit;
  }

  .header-top-area .navbar-light .navbar-toggler.collapsed::after {
    content: "";
    background: #ffffff;
    width: 26px;
    height: 2px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 14px;
    right: 0;
    transition: 0.4s all ease;
    -webkit-transform: inherit;
            transform: inherit;
  }

  .header-top-area .navbar-light .navbar-toggler::before {
    content: "";
    background: #ffffff;
    width: 26px;
    height: 2px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    transition: 0.4s all ease;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .header-top-area .navbar-light .navbar-toggler::after {
    content: "";
    background: #ffffff;
    width: 26px;
    height: 2px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 8px;
    right: 0;
    transition: 0.4s all ease;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }

  .navbar-collapse {
    position: fixed !important;
    top: 0;
    background: #16212c;
    bottom: 0;
    width: 300px;
    border-right: 1px solid #252831;
    left: -300px;
    transition: 0.4s all ease !important;
    display: block !important;
    flex-grow: inherit !important;
    flex-basis: inherit !important;
    height: 100vh !important;
  }

  .navbar-collapse.show {
    left: 0;
  }
  .header-top-area .navbar-expand-lg .navbar-nav .nav-link {
    padding: 20px 30px;
  }
  .header-top-area .navbar-expand-lg .navbar-nav .nav-link:first-child {
    padding-left: 30px;
  }
  .header-top-area .navbar-light .navbar-nav .nav-link.active span::after {
    display: none;
  }
  .header-top-area .navbar-light .navbar-nav .nav-link.active {
    color: #f6ad75;
  }
  .mobile-login {
    position: fixed;
    bottom: 30px;
    left: 30px;
    max-width: inherit;
    width: inherit;
  }

  .header-top-login.mobile-login ul {
    display: flex;
    justify-content: center;
  }
  .header-top-login ul li span {
    font-size: 14px;
    padding: 8px 10px;
    min-width: 108px;
  }

  .header-top-login ul li:first-child span {
    background: #1e2e3b;
    border-radius: 3px;
  }

  .navbar-nav {
    padding-top: 10px;
  }
  .header-logo-mobile {
    display: block;
    padding-left: 30px;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .show .mobile-login {
    display: block;
  }
  .header-logo-mobile img {
    max-width: 224px;
  }

  .header-logo-mobile h2 {
    color: #f6ad75;
    font-weight: 700;
    font-size: 36px;
    letter-spacing: 0.5px;
    margin: 0;
  }

  .header-logo-mobile a:hover {
    text-decoration: none;
  }
}

@media screen and (max-width: 340px) {
  .navbar-collapse {
    width: 260px;
  }
  .header-top-login ul li span {
    font-size: 13px;
    padding: 8px 10px;
    min-width: 90px;
  }

  .header-top-area .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 14px;
  }
}

button{
  font-weight: 600 !important;
  color: #1b1f22!important;
}
.history-popup .modal-content {
    background: linear-gradient( 
180deg
 , #1f2f3c, #16212c);
    z-index: 999999;
}
.history-popup .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 30px;
}
.history-popup-content .user-image-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.history-popup-content .user-image-section img {
    height: 120px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px #141f27;
}

.history-popup-content .user-image-section .round-id-section {
    width: calc(100% - 145px);
}

.round-id-section h6 {
    BACKGROUND: #112231;
    color: white;
    padding: 15px 10px;
    font-weight: 400;
    font-size: 13px;
    border-radius: 5px;
}
.payout-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.payout-section .bet {
    width: 30%;
}
.payout-section .bet h6{
    BACKGROUND: #112231;
    color: white;
    padding: 15px 10px;
    font-weight: 400;
    font-size: 13px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}
.payout-section .bet p {
    color: #cecccc;
    margin-bottom: 0px;
    font-size: 12px;
}
.payout-textid .bet p {
    color: #cecccc;
    margin-bottom: 0px;
    font-size: 12px;
}
.payout-textid .bet h6{
    BACKGROUND: #112231;
    color: white;
    padding: 15px 10px;
    font-weight: 400;
    font-size: 13px;
    border-radius: 5px;
}
.payout-textid {
    margin-top: 15px;
}
.payout-textid h6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.history-heading img {
    height: 15px;
    cursor: pointer;
}

.history-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.history-heading h6 {
    color: white;
    font-size: 20px;
}
.payout-textid i {
    BACKGROUND: #1d486f;
    color: white;
    padding: 6px;
    border-radius: 3px;
    margin-left: 2px;
    cursor: pointer;
}
.limit-modal .modal-content{
    background-color: #001842;
}
.limit-modal .modal-header{
    padding: 15px 26px;
    border: none;
}
.limit-modal .modal-title{
    /* color: #7c8fa0; */
    color: #a7bbcd;
    margin: 0;
    font-size: 16px;
}
.limit-modal .close{
    color: #7c8fa0;
    font-weight: 300;
    font-size: 20px !important;
    box-shadow: none;
    text-shadow: none;
}
.limit-modal .close:hover{
    color: #fff;
}
.limit-modal .modal-body{
    padding-top: 0;
}
.limit-modal-table .table-responsive thead {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    background: #03071e;
}
.limit-modal-table .table-responsive thead th {
    color: #fff;
    font-weight: 500;
}
.limit-modal-table .table-bordered{
    border: none;
}
.limit-modal-table .table-bordered thead td,
.limit-modal-table .table-bordered thead th{
    border: none;
    color: #fff;
    font-weight: 500;
    border-bottom: 1px solid hsla(0,0%,84.3%,.058823529411764705);
}
.limit-modal-table .table-bordered td,
.limit-modal-table .table-bordered th{
    border: none;
    border-bottom: 1px solid hsla(0,0%,84.3%,.058823529411764705);
    /* color: #7c8fa0; */
    font-size: 14px;
    color: #a7bbcd;;
}
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1400px !important;
  }
}
@media screen and (min-width: 991px) {
  .md-ddnone {
    display: none !important;
  }
  .roulette-game-page .select-chips-section h6 > span {
    display: none;
  }
}

@media screen and (max-width: 1366px) {
  .container {
    max-width: 1220px !important;
  }
  .rolulette-right-section {
    width: 60%;
  }
  .roulette-game-page .roulette-table-section {
    width: 40%;
  }
  .roulette-bet-table table.bet-table td {
    font-size: 12px;
    height: 40px;
    width: 28px;
  }
  .roulette-bet-table table.bet-table td:last-child {
    font-size: 12px;
  }
  .roulette-bet-table .grid-bet-table > div {
    font-size: 12px;
  }
  .roulette-game-page .select-chips-section h6 {
    font-size: 12px;
  }
  .roulette-bet-table table.bet-table tr td:first-child,
  .roulette-bet-table table.bet-table tr td:last-child {
    width: 35px;
  }
  td.selected-area-zero {
    width: 3px !important;
  }
  td.selected-area {
    width: 3px !important;
  }
}
@media screen and (min-width: 991px) {
  .roulette-game-page .winner-section {
    width: 95%;
    margin-top: 50px;
    margin-left: auto;
    margin-right: 0;
  }
  .rolulette-right-section {
    width: 56%;
  }
}
@media (max-width: 1024px) and (min-width: 991px) {
  .rolulette-right-table-section {
    overflow-x: auto;
    padding: 34px 20px !important;
  }
  .rolulette-right-table-section {
    overflow-x: auto;
    padding: 0px 0px 10px !important;
  }
}

@media screen and (max-width: 991px) {
  .payout-textid .bet h6 {
    overflow: hidden;
    padding: 0px 10px !important;
  }
  .bottom-footer {
    padding-bottom: 50px;
  }
  .roulette-bet-table .grid-bet-table {
    font-size: 13px;
    height: 100px;
    width: 393px;
    grid-template-columns: repeat(6, 65.4px);
    left: 23px;
    height: 85px;
    margin-top: -1px;
    left: 19px;
  }
  .roulette-bet-table {
    width: 585px;
  }

  .stat-meter {
    margin: 0 0px;
  }
  td.selected-area,
  td.selected-area-zero {
    width: 1px !important;
  }
  .roulette-bet-table table.bet-table tr td:first-child,
  .roulette-bet-table table.bet-table tr td:last-child {
    width: 15px !important;
  }
  .sm-ddnone {
    display: none !important;
  }
  .select-option {
    position: absolute;
    top: 37px;
    z-index: 9;
  }
  .custom-select-option h6 {
    font-size: 12px;
  }
  .select-option p {
    font-size: 12px;
  }
  .roulette-game-page .roulette-table-section {
    width: 100%;
  }
  .rolulette-right-section {
    width: 100%;
  }
  .rolulette-right-table-section {
    overflow-x: auto;
    padding: 0 0 30px;
    margin: 0 auto 20px;
  }
  .rolulette-right-section .roulette-bet-table {
    /* transform: rotate(90deg);
    margin-top: -246px; */
    margin-bottom: 50px;
  }
  .game-table-section {
    display: inline;
  }
  .roulette-game-page .bottom-table .nav-tabs a {
    width: 33%;
    padding: 10px 25px;
  }
  .bottom-table .table-responsive table tr td,
  .bottom-table .table-responsive table tr th {
    white-space: nowrap;
    padding: 10px 20px;
  }

  .roulette-bet-table table.bet-table td {
    height: 35px;
    width: 13px;
  }

  .roulette-table-section .main {
    max-width: 350px;
    margin: 20px auto 0px;
    position: relative;
    width: 100%;
    margin: -74px auto 0px !important;
  }

  .data-inner span {
    color: #fff;
    font-size: 15px;
  }

  .data .mask,
  .data .result {
    font-size: 15px;
  }
  .bet-revert-section {
    width: 180px;
  }

  .bet-section {
    margin-left: 12px;
    padding: 3px;
    justify-content: space-around;
  }

  .bet-section .bet-box {
    font-size: 10px;
    height: 30px;
    width: 30px;
    line-height: 28px;
  }

  /* .rolulette-right-section {
    display: none;
  } */

  /* .roulette-game-page .winner-section {
    display: none;
  } */

  .roulette-game-page .winner-section {
    width: 100%;
    margin: 10px 0;
    max-width: 550px;
    margin: 15px auto;
  }
  .roulette-bet-table {
    padding-top: 5px;
  }

  .roulette-game-page .select-chips-section {
    padding: 20px 25px !important;
    margin: 0;
  }
  .roulette-game-page .select-chips-section h6 > span {
    float: right;
    padding-right: 10px;
  }
  .roulette-game-page .bet-section {
    background: transparent;
    padding: 0;
  }
  .payout-bet-section {
    justify-content: center;
    width: 90%;
    margin: auto;
  }
  .payouts-game {
    margin-right: 3%;
    /* margin-top: 20px; */
    max-width: 47%;
    width: 47%;
    margin-left: 0;
  }
  /* .bet-revert-section {
    margin-left: 3%;
    margin-right: 0;
    max-width: 47%;
    width: 47%;
  } */

  .btn-sm {
    border-color: #eb2958;
    background-image: linear-gradient(-180deg, #eb2958 0%, #eb2958 100%);
    box-shadow: 0 1rem 1.25rem 0 rgb(22 75 195 / 0%),
      0 -0.25rem 1.5rem rgb(235 41 88) inset,
      0 0.75rem 0.5rem rgb(255 255 255 / 40%) inset,
      0 0.25rem 0.5rem 0 rgb(141 4 38) inset;
    font-size: 12px;
    transition: 0.2s;
    color: white !important;
    font-weight: 500 !important;
  }

  .btn-sm:hover,
  .btn-sm:focus {
    background-color: #eb2958;
    border-color: #97324b;
    box-shadow: none;
    outline: none;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    outline: none;
    background-color: #97324b;
    border-color: #97324b;
  }

  .main-game-list .mobile-view-button {
    display: block;
  }

  .small-bet-section {
    display: block;
    width: 80vw;
    min-height: 100vh;
    /* background: url("assets/images/roulette/home-bg4.png"); */
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background: linear-gradient(180deg, #001a47, #03051a);
    position: fixed;
    z-index: 9999999 !important;
    right: -80vw;
    padding: 15px;
    top: 0px;
    margin: auto 0;
    bottom: 0px;
    transition: 0.5s all;
    height: 100% !important;
  }

  .open-bet-section {
    right: 0vw;
  }

  .small-bet-container .close-bet-section {
    display: flex;
    padding: 30px 10px 0px 20px;
    justify-content: space-between;
    position: relative;
  }

  .close-bet-section span {
    color: #fff;
    font-size: 14px;
  }
  .close-bet-section img {
    width: 21px;
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 15px;
  }
  .statistic-section {
    margin-top: 10%;
    border-radius: 3px;
  }
  .small-place-bet {
    /* height: 130px; */
    background-color: #011338;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .small-place-bet .bet-revert-section {
    width: 100%;
    background: transparent;
    height: auto;
  }

  .small-place-bet .chips-box-list {
    padding: 10px;
  }
  /* .bet-total-container-child {
    width: 39%;
    margin: 0 5px 0 0;
  }
  .bet-total-container-child:last-child {
    width: 59%;
    margin: 0 0 0 5px;
  } */
  .bet-total-container-child .bet-heading,
  .bet-total-container-child .bet-amount {
    font-size: 10px;
  }
  .bet-total-container {
    margin-top: 5px;
  }

  .small-place-bet .chips-box-list .bet-coins .bet-box {
    min-height: 16px;
    min-width: 30px;
    font-size: 9px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .statics-container {
    display: none;
  }

  .small-bet-container {
    position: relative;
    height: 100%;
  }

  /* .roulette-bet-table .grid-bet-table {
		width: 313px;
		grid-template-columns: repeat(6, 51.9px);
		left: 12px;
	} */
  .bet-section {
    margin-left: 5px;
    padding: 3px;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px auto 0;
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
    justify-content: center;
  }
  .statistic-section .stastics .left-statistic-section,
  .statistic-section .stastics .right-statistic-section {
    width: 100%;
  }
  .statistic-section .stastics {
    display: block;
  }
  .roulette-game-page .bottom-table .nav-tabs a {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 500;
  }
  .bet-coins {
    width: 100%;
    /* justify-content: center; */
  }
  .chips-box-list {
    display: block;
  }
  .bet-total-container {
    width: 100%;
    padding-left: 10px;
  }
  h6.bet-heading {
    color: #9893cb;
    line-height: initial;
    font-size: 12px;
    margin-bottom: 0px;
  }
  .table-section {
    height: calc(100vh - 270px);
    overflow: auto;
    position: relative;
  }
  .roulette-bet-table table.bet-table tr td:first-child,
  .roulette-bet-table table.bet-table tr td:last-child {
    width: 18px !important;
  }
  .roulette-bet-table table.bet-table td:last-child {
    font-size: 10px;
  }
  /********* Welcome popup ************/
  .welcome-popup .welcome-btn {
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: center;
  }
  .welcome-btn .btn-primary {
    margin: 32px 0px 0;
    width: 150px;
    height: 40px;
    border-radius: 4px;
  }
  .welcome-img h2 {
    font-size: 18px !important;
  }

  .bet-total-container {
    width: 100%;
    padding: 10px 0;
    display: flex;
    /* flex-direction: revert; */
    justify-content: space-between;
  }
  .bet-total-container2 {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  .bet-total-container-child {
    width: 100%;
    margin-right: 0%;
  }
  .bet-total-container-child:last-child {
    width: 100%;
    margin-right: none;
  }
  .bet-total-container-child .bet-heading {
    width: 56%;
    margin-right: 1px;
  }
  .small-bet-section .roulette-bet-table {
    position: absolute;
    padding-top: 0;
    top: 55%;
    -webkit-transform: translateY(0%) rotate(-90deg);
            transform: translateY(0%) rotate(-90deg);
    left: 0;
  }
  .payout-bet-section {
    display: none;
  }
  .roulette-table-section .bet-section {
    display: flex;
  }

  /* .revert-bet-section {
  justify-content: flex-start;
  width: fit-content;
  margin-top: 0;
} */
  .revert-bet-section {
    justify-content: space-between;
    width: -moz-fit-content;
    width: 100%;
    margin-top: 0;
  }
  .place-bet-heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .roulette-game-page .winner-section {
    margin-top: 35px;
  }
  .bet-total-container-child h6 {
    white-space: nowrap;
  }
  .statistic-section {
    background: linear-gradient(180deg, #03081f, #03051a);
    padding: 20px;
  }
  .sticky-footer img {
    height: 15px !important;
    width: 15px !important;
  }
  .rules-popup .modal-content {
    margin-top: 0px;
}
.popup-btn button{
  width: 45% !important;
}
.rules-popup .nav-tabs a {
  padding: 10px;
}

}
@media screen and (max-width: 480px) {
  .winner-section.last-result .result-listing {
    display: flex;
    /* border-top: 1px dashed #434343; */
    padding: 3px 0px;
    margin: 5px 0px;
    width: 100%;
  }
  .small-bet-section .roulette-bet-table {
    position: absolute;
    padding-top: 0;
    top: 50%;
    -webkit-transform: translateY(0%) rotate(-90deg);
            transform: translateY(0%) rotate(-90deg);
    left: -50%;
  }
  .roulette-game-page .select-chips-section {
    padding: 20px 15px !important;
  }
  .payouts-game {
    margin-top: 10%;
  }
}
@media screen and (max-width: 380px) {
  .bet-section {
    margin-top: 20px;
  }
  .bet-section .bet-box {
    margin: 5px 2px;
  }
  .popup-btn button {
    width: 45% !important;
  }
  .popup-img img {
    margin: 25px auto 0;
    width: 95px;
    height: 115px;
  }
  .popup-img h2 {
    font-size: 18px;
  }
}
@media screen and (max-width: 375px) {
  .rules-box span {
    font-size: 10px;
    padding: 4px 5px;
    min-width: 55px;
  }
  .chips-box-list .bet-box {
    min-height: 30px;
    min-width: 30px;
    line-height: 30px;
  }
  .sticky-footer button#dropdown-profile {
    font-size: 10px;
  }
  .bet-box img {
    height: 30px !important;
    width: 30px !important;
  }
  .sticky-footer img {
    height: 12px !important;
    width: 12px !important;
  }
  .sticky-footer .dropdown-toggle::after {
    height: 12px;
    width: 12px;
    margin-left: 5px;
  }
  .roulette-table-section .main {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    margin-left: 0px !important;
  }
  /* .small-bet-section .roulette-bet-table {
    transform: rotate(-90deg);
    margin-top: 0;
    height: 100%;
    position: absolute;
    top: 20px;
    transform: translate(-50%, 50%) rotate(-90deg);
    padding-top: 0px;
    left: 75%;
  } */
  .small-bet-section .roulette-bet-table {
    position: absolute;
    padding-top: 0;
    top: 75%;
    -webkit-transform: translateY(0%) rotate(-90deg);
            transform: translateY(0%) rotate(-90deg);
    left: -50%;
  }

  .winner-user-section {
    margin-left: 0px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .rules-popup .nav-tabs a {
    padding: 12px;
    padding: 8px;
  }
  .back-drop-shadow:before {
    z-index: 9;
  }
  .bet-revert-section {
    margin-right: 0px;
    top: -10px;
}
}

@media screen and (max-width: 374px) {
  .plate {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
}

